import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  userinfo: [],
  useredit: [],
  contactus: [],
};

const UserSlice = createSlice({
  name: "userReducers",
  initialState,
  reducers: {
    infoReq: (state) => {
      state.loading = true;
    },
    infosucess: (state, action) => {
      state.loading = false;
      state.userinfo = action.payload;
    },
    infoFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;

      // token expired or something went wrong
      state.userinfo = null;
    },
    infoeditReq: (state) => {
      state.loading = true;
    },
    infoeditsucess: (state, action) => {
      state.loading = false;
      state.useredit = action.payload;
    },
    infoeditFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    imgeditreuqest: (state) => {
      state.loading = true;
    },
    imgeditsucess: (state, action) => {
      state.loading = false;
      state.useredit = action.payload;
    },
    imgeditfail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    contactusReq: (state) => {
      state.loading = true;
    },
    contactussucess: (state, action) => {
      state.loading = false;
      state.contactus = action.payload;
    },
    contactusFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const UserActions = UserSlice.actions;
export default UserSlice;
