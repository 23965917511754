import mehantiApi from "../api";
import useAsync from "./useAsync";
import notifyUtils from "../Utils/notify";
import { useEffect } from "react";

export default function useDegreeList() {
  const { result: degreeList, main: getDegreeList } = useAsync({
    fn: mehantiApi.getDegreeList,
    onError: () => {
      notifyUtils.error("Error getting degrees!");
    },
  });

  useEffect(() => {
    getDegreeList();
  }, []);

  return {
    degreeList,
    getDegreeList,
  };
}
