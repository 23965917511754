import mehantiApi from "../api";
import useAsync from "./useAsync";
import { useEffect } from "react";

export default function useDistrictsList() {
  const { main: getDistrictsList, result: districtsList } = useAsync({
    fn: mehantiApi.getDistrictsList,
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    getDistrictsList();
  }, []);
  return { getDistrictsList, districtsList };
}
