import React, { useState, useEffect } from "react";
import "./QnA.scss";
import heading from "../../assets/images/profile/Path 489.png";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import useAsync from "../../hooks/useAsync";
import notifyUtils from "../../Utils/notify";
import mehnatiApi from "../../api";

function QnA() {
  const {
    result: questionsAnswers,
    main: getQuestionAnswers,
    error,
  } = useAsync({
    fn: mehnatiApi.getQuestionAnswers,
    onError: () => {
      notifyUtils.error("Error getting questions!");
    },
  });

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    getQuestionAnswers();
  }, []);

  return (
    <div className="Main_qna">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Q&A</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>
            <span className="head_span">Q&A</span>
          </div>
        </div>
      </div>
      <div className="qna_cont">
        <div className="app">
          <div className="app__head">
            <h1>Questions and answers about Mehnati</h1>
          </div>
          <div className="app__content">
            <div className="accordion-container">
              {questionsAnswers &&
                questionsAnswers.map((item, index) => (
                  <div
                    className={`accordion ${index === activeIndex ? "is-open" : ""}`}
                    key={index}
                  >
                    <header
                      className="accordion__header"
                      onClick={() => toggleAccordion(index)}
                    >
                      <p>
                        <button>
                          <span className="accordion__title">
                            {item.question}
                          </span>
                          <div className="accordion__icon">
                            {index === activeIndex ? (
                              <AiOutlineMinus size={24} />
                            ) : (
                              <AiOutlinePlus size={24} />
                            )}
                          </div>
                        </button>
                      </p>
                    </header>
                    {index === activeIndex && (
                      <div className="accordion__content">
                        <div className="accordion__inner">{item.answer}</div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnA;
