import { useEffect } from "react";
import { useDispatch } from "react-redux";
import isAuth from "../Utils/isAuth";
import useSavedJobs from "./useSavedJobs";

const useAuthAndFetchSavedJobs = () => {
  const dispatch = useDispatch();
  const { getSavedJobs, savedJobs } = useSavedJobs();

  useEffect(() => {
    if (isAuth(dispatch)) getSavedJobs();
  }, [dispatch]);

  return { savedJobs, getSavedJobs };
};

export default useAuthAndFetchSavedJobs;
