const API_ROUTES = {
  GET: {
    DISTRICTS: "/disrict",
    EXPERTISE: "/expertise",
    EXPERIENCE: "/experience",
    DEGREE: "/education",
    QA: "/qa/",
    ABOUT_US: "/aboutus/getAll",
    ACTIVITY: "/activity/getAll",
    CATEGORY: "/category/getAll",
    COMPANY: "/company/getAll",
    COMPANY_BY_ID: (companyId) => `/company/getOne/${companyId}`,
    COMPANY_STATS: "/company/stats",
    COMPANY_STATISTICS: "/",
    SUCCESS_STORIES: "/story",
    SAVED_JOBS: "/favorite",
    HOMEPAGE_COMPANIES: "/company/top10",
    GET_JOBS: "/job",
    GET_JOB_BY_ID: (jobId) => `/job/${jobId}`,
    GET_IS_JOB_APPLIED: (jobId) => `/apply/check/${jobId}`,
    GET_APPLIED_JOBS: "/apply",
    GET_USER_INFO: "/user/myInfo",
  },
  POST: {
    CREATE_CV: "/cv",
    SAVE_JOB: "/favorite",
    APPLY_FOR_JOB: "/apply",
    CONTACT_US: "/contact",
    REGISTER:"/user/",
  },
  PUT: {
    EDIT_MY_INFO: "/user",
    EDIT_MY_IMAGE: "/user/assigne",
  },
  DELETE: {
    DELETE_SAVED_JOB: (jobId) => `/favorite/${jobId}`,
  },
};

export default API_ROUTES;
