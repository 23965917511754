import useAsync from "./useAsync";
import mehnatiApi from "../api";
import notifyUtils from "../Utils/notify";

export default function useUserInfo() {
  const {
    main: getMyInfo,
    loading: loadingUserInfo,
    result: userInfo,
  } = useAsync({
    fn: mehnatiApi.getMyInfo,
    onError: () => {
      console.log("userinfo");
      notifyUtils.error("Failed to get user info");
    },
  });

  const { editMyInfo } = useAsync({
    fn: mehnatiApi.editMyInfo,
    onError: () => {
      notifyUtils.error("Failed to update user info");
    },
    onSuccess: () => {
      notifyUtils.success("User info updated successfully");
    },
  });

  const { editMyImg } = useAsync({
    fn: mehnatiApi.editMyImg,
    onError: () => {
      notifyUtils.error("Failed to update user img");
    },
  });

  return { editMyInfo, editMyImg, getMyInfo, userInfo, loadingUserInfo };
}
