import { useCallback, useEffect, useState } from "react";

const errorMessage = (error) => {
  return (
    (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
    error?.message ||
    error?.toString()
  );
};

export default function useAsync({
  fn = () => {},
  onSuccess = () => {},
  onError = () => {},
  callOnMount = false,
}) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState(null);

  const main = async (data) => {
    try {
      setLoading(true);
      setRequestData(data);
      const res = await fn(data);
      setResult(res);
      setSuccess(true);
    } catch (err) {
      setError(errorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const reset = useCallback(() => {
    setError(null);
    setSuccess(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (success) {
      onSuccess();
    }

    if (error) {
      onError();
    }

    return () => {
      reset();
    };
  }, [success, error, reset, onError, onSuccess]);

  useEffect(() => {
    if (callOnMount) {
      main();
    }
  }, []);

  return { main, error, success, loading, result, requestData };
}
