import React, { useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home/Home";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import AboutUs from "./AboutUs/AboutUs";
import Auth from "./Auth/Auth";
import ContactUs from "./ContactUs/ContactUs";
import CompleteProfileStep1 from "./CompleteProfile/CompleteProfileStep1";
import CvBuilder from "./CvBuilder/CvBuilder";
import EditProfile from "./EditProfile/EditProfile";
import Profile from "./Profile/Profile";
import Login from "./Login/Login";
import RegisterCompany from "./RegisterCompany/RegisterCompany";
import JobListing from "./JobListing/JobListing";
import SavedjobList from "./SavedjobList/SavedjobList";
import JobDetails from "./JobDetails/JobDetails";
import QnA from "./Q&A/QnA";
import SuccessStories from "./Success Stories/SuccessStories";
import AppliedJobsList from "./AppliedJobsList/AppliedJobsList";

import Companies from "./Companies/Companies";
import { useLocation } from "react-router-dom";
import Company from "./Company/Company";
import ChangePassword from "./ChangePassword/ChangePassword";

function PageSwitch() {
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/completeprofile" element={<CompleteProfileStep1 />} />
        <Route path="/cvbuilder" element={<CvBuilder />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registercompany" element={<RegisterCompany />} />
        <Route path="/joblisting" element={<JobListing />} />
        <Route path="/savedjoblist" element={<SavedjobList />} />
        <Route path="/jobdetails/:id" element={<JobDetails />} />
        <Route path="/qna" element={<QnA />} />
        <Route path="/successstories" element={<SuccessStories />} />
        <Route path="/appliedjobslist" element={<AppliedJobsList />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/company/:id" element={<Company />} />
      </Routes>
      <Footer />
    </>
  );
}

export default PageSwitch;
