import React, { useState, useEffect } from "react";

import imagehead from "../../assets/images/profile/headingc.png";
import profile from "./../../assets/images/profile/Path 786.png";
import facebook from "./../../assets/images/profile/Group 55.png";
import linked from "./../../assets/images/profile/Group 57.png";
import instagram from "./../../assets/images/profile/Group 56.png";
import { IoMailOutline } from "react-icons/io5";
import { FaFirefox } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { RxActivityLog } from "react-icons/rx";
import JobListingCard from "../../Components/Profile/JobListCard";
import "./Company.css";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BsPhone } from "react-icons/bs";
import Loader from "./../../Components/Loader/Loader";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
import useSavedJobs from "../../hooks/useSavedJobs";
import isAuth from "../../Utils/isAuth";
import { useDispatch } from "react-redux";
import useAuthAndFetchSavedJobs from "../../hooks/useAuthAndFetchSavedJobs";
function Company() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [favoritesJobsArray, setFavoritesJobsArray] = useState([]);

  const {
    result: companyInfo,
    main: getCompanyById,
    loading,
  } = useAsync({
    fn: mehnatiApi.getCompanyById,
    onError: () => {
      notifyUtils.error("Failed to get company information");
    },
  });

  const { savedJobs } = useAuthAndFetchSavedJobs();

  useEffect(() => {
    if(id)
    getCompanyById({
      companyId: id,
    });
  }, [id]);

  useEffect(() => {
    if (savedJobs?.data?.length > 0) {
      savedJobs?.data?.map((job) => {
        if (!favoritesJobsArray.includes(job?.job_id)) {
          setFavoritesJobsArray((favoritesJobsArray) => [
            ...favoritesJobsArray,
            job?.job_id,
          ]);
        }
      });
    }
  }, [savedJobs]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="single-page">
        <div className="bothpic">
          <img src={profile} className="profile" />
          <img src={imagehead} className="fist-image" />
          <img
            src={process.env.REACT_APP_API + "/company/" + companyInfo?.data?.img_url}
            className="img-profile"
          />
        </div>
        <div className="secondcont">
          <div className="title">
            <div className="title-first">{companyInfo?.data?.full_name}</div>

            <div className="set-linked">
              <a
                href={companyInfo?.data?.facebook}
                className="linked"
                target="_blank"
              >
                <img src={facebook} className="linked" />
              </a>
              <a
                href={companyInfo?.data?.instagram}
                className="linked"
                target="_blank"
              >
                <img src={instagram} className="linked" />
              </a>

              <a
                href={companyInfo?.data?.linked_in}
                className="linked"
                target="_blank"
              >
                <img src={linked} className="linked" />
              </a>
              <Link
                to={companyInfo?.data?.website}
                className="linked"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFirefox size={30} />
              </Link>
            </div>
          </div>
          <div className="setofdetails">
            <div className="datials">
              <div className="item">
                <CiLocationOn />{" "}
                {companyInfo?.data?.district?.name_en
                  ? companyInfo?.data?.district?.name_en
                  : "Location not available"}
              </div>
            </div>

            <div className="datials">
              <div className="item">
                <RxActivityLog />{" "}
                {companyInfo?.data &&
                  companyInfo.data?.company_activities &&
                  companyInfo.data?.company_activities[0] &&
                  companyInfo.data?.company_activities[0].activity &&
                  companyInfo.data?.company_activities[0].activity.title}
              </div>
            </div>
          </div>

          <div className="title-first">About the company</div>
          <div className="description">{companyInfo?.data?.description}</div>
          <div className="title-first">Posted Jobs</div>

          <div className="jobs_container">
            {companyInfo?.jobs?.length == 0 && (
              <div className="description">No Posted Job</div>
            )}
            {companyInfo?.jobs &&
              companyInfo?.jobs.map((job) => {
                let isSaved = false;
                if (favoritesJobsArray.includes(job.id)) {
                  isSaved = true;
                }
                return (
                  <JobListingCard
                    position={job.title_en}
                    name={job.company.full_name}
                    location={job.district.name_en}
                    experience={job.experience_level.name_en}
                    education={job.education_level.name_en}
                    gender={job.gender}
                    payment={job.currrency}
                    desc={job.description_en}
                    type={job.job_type}
                    date={job.date}
                    image={job.company?.img_url}
                    id={job.id}
                    saved={isSaved}
                    expired={job.expired}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default Company;
