import axios from "axios";
import { AuthActions } from "./AuthReducers";
import setAuthToken from "../../Utils/setAuthToken";
import mehnatiAxios from "../../lib/axios";
export const user_Login = (email, password) => async (dispatch) => {
  try {
    dispatch(AuthActions.LoginRequest());

    const res = await mehnatiAxios.post("/user/logIn", {
      email: email,
      password: password,
    });

    if (res?.data?.data?.role == 3) {
      dispatch(AuthActions.LoginSuccess(res.data));
      const token = res?.data?.token;
      localStorage.setItem("token", token);
      setAuthToken(token);
      window.location.href = "/";
    } else {
      dispatch(AuthActions.LoginFail("You are not user !"));
    }
  } catch (error) {
    dispatch(AuthActions.LoginFail(error));
  }
};

export const Register = (user) => async (dispatch) => {
  try {
    dispatch(AuthActions.RegisterRequest());
    const { data } = await mehnatiAxios.post("/user/", user);
    dispatch(AuthActions.RegisterSuccess(data));
  } catch (error) {
    console.error("Registration Error:", error);
    dispatch(AuthActions.RegisterFail(error.response?.data?.message));
  }
};
export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    setAuthToken(false);
    window.location.href = "/";
    dispatch(AuthActions.logout());
    localStorage.removeItem("token");
    setAuthToken(false);
  } catch (error) {}
};

export const CompanyRegister = (data) => async (dispatch) => {
  try {
    dispatch(AuthActions.RegisterCompanyReq());
    const res = await mehnatiAxios.post("/company", data);
    dispatch(AuthActions.RegisterCompanySuccess(res.data));
  } catch (error) {
    dispatch(AuthActions.RegisterCompanyFail(error.response?.data?.message));
  }
};

export const ChangePassword1 =
  (oldPassword, newPassword) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch(AuthActions.changepasswordRequest());
      const res = await mehnatiAxios.put("/user/ChangePassword", {
        oldPassword,
        newPassword,
      });
      dispatch(AuthActions.changepasswordSuccess(res.data));
    } catch (error) {
      dispatch(AuthActions.changepasswordFail(error.response?.data?.message));
    }
  };
export const forgetPassword = (email) => async (dispatch) => {
  try {
    dispatch(AuthActions.ForgetPasswordRequest());
    const res = await mehnatiAxios.put("/user/forgetPassword", {
      email: email,
    });
    dispatch(AuthActions.ForgetPasswordSuccess(res.data.data));
  } catch (error) {
    dispatch(AuthActions.ForgetPasswordFail(error));
  }
};
