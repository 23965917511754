import React, { useEffect } from "react";
import "./JobDetails.css";
import heading from "../../assets/images/aboutus/Path 489.svg";
import JobListingCard from "../../Components/Profile/JobListCard";
import { BsBuildingFillCheck } from "react-icons/bs";
import { BsPhone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import yellowbg from "../../assets/images/home/Path 192.webp";
import greenbg from "../../assets/images/home/Path 193.webp";
import redbg from "../../assets/images/home/Path 191.webp";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import isAuth from "../../Utils/isAuth";
import useJobs from "../../hooks/useJobs";
import useAuthAndFetchSavedJobs from "../../hooks/useAuthAndFetchSavedJobs";

function JobDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    getJobs,
    jobs,
    getJobById,
    job,
    applyForJob,
    appliedForJob,
    checkIfJobApplied,
    loadingJob: loading,
  } = useJobs({
    onApplySuccess: () => {
      checkIfJobApplied({
        id,
      });
    },
  });
  const { savedJobs } = useAuthAndFetchSavedJobs();

  const handleapply = async () => {
    if (!isAuth(dispatch)) {
      navigate("/login");
      return;
    }
    applyForJob({
      id,
    });
  };

  useEffect(() => {
    getJobs();
    if (id) {
      getJobById({
        id,
      });
      checkIfJobApplied({
        id,
      });
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="Main_job_details">
        <div className="Aboutus_heading">
          <div className="Aboutus_heading_img">
            <img src={heading} alt="heading" className="heading" />
          </div>
        </div>
        <div className="profile_cont">
          <div className="profile_img_cont">
            <div className="profile_img_pro">
              {" "}
              <div className="home_stats_cont">
                <div className="home_stats_item_icon">
                  <img
                    src={redbg}
                    alt="building"
                    className="home_stats_icon1111"
                  />
                </div>
                <div className="home_stats_item_text1212">
                  <div className="home_stats_item_number111">
                    {job?.category?.category?.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="FirstPart_Container">
          <div className="small_cont">
            <div className="Job_Title"> {job?.data?.title_en} </div>
            {job?.expired === true ? (
              <button className="Apply_button">Expired</button>
            ) : !appliedForJob ? (
              <button
                className="Apply_button"
                onClick={() => {
                  handleapply();
                }}
              >
                Apply
              </button>
            ) : (
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontFamily: "cursive",
                }}
              >
                Matching or Disqualified
              </p>
            )}
          </div>
          {appliedForJob && (
            <div className="message_app"> Applied Successfully </div>
          )}

          <div className="Job_Title_info"> {job?.data?.expertise?.name_en}</div>
          <div className="Icons_Container">
            <div className="label">
              {" "}
              <TbWorld size={30} /> Post Date: {job?.data?.date}
            </div>
            <div
              className="label"
              onClick={() => navigate(`/company/${job?.data?.company_id}`)}
            >
              {" "}
              <BsBuildingFillCheck size={30} /> {job?.data?.company?.full_name}
            </div>
          </div>
        </div>
        <div className="SecondPart_Container">
          <div className="containersecond">
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img src={redbg} alt="building" className="home_stats_icon1" />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {job?.data?.job_type}
                </div>
              </div>
            </div>
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img
                  src={yellowbg}
                  alt="building"
                  className="home_stats_icon1"
                />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {job?.data?.experience_level?.name_en}
                </div>
              </div>
            </div>
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img
                  src={greenbg}
                  alt="building"
                  className="home_stats_icon1"
                />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {job?.data?.education_level?.name_en}
                </div>
              </div>
            </div>
            {/* <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon1" />
            </div>
            <div className="home_stats_item_text">
              <img
                src={icon1}
                alt="building"
                className="home_stats_miniicons"
              />
              <div className="home_stats_item_number">{job.gender}</div>
            </div>
          </div> */}
            {/* <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon1" />
            </div>
            <div className="home_stats_item_text">
              <img
                src={icon5}
                alt="building"
                className="home_stats_miniicons"
              />
              <div className="home_stats_item_number">
                {job.salary} {job.currrency}
              </div>
            </div>
          </div> */}
          </div>
        </div>

        <div className="desc_cont">
          <div className="Title"> Job Description </div>
          <div className="Job_Desc_Text">
            <ol>
              {job?.data?.description_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>

        <div className="require_cont">
          <div className="Title"> Requirements </div>
          <div className="Job_Desc_Text">
            <ol>
              {job?.data?.requirement_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>
        <div className="qual_div">
          <div className="Title"> Qualifications and Skills</div>
          <div className="Job_Desc_Text">
            <ol>
              {job?.data?.qualification_skill_en
                ?.split("-")
                .map((segment, index) => (
                  <li key={index}>{segment}</li>
                ))}
            </ol>
          </div>
        </div>

        <div className="benefits_cont">
          <div className="Title"> Benefits </div>
          <div className="Job_Desc_Text">
            <ol>
              {job?.data?.benefit_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>

        <div className="rel_list">
          <div className="Title"> Related Jobs </div>
          <div className="jobs_info">
            {loading ? (
              <Loader />
            ) : (
              <>
                {jobs &&
                  jobs.slice(0, 3).map((job) => {
                    const saved =
                      savedJobs &&
                      savedJobs.find((savedJob) => {
                        return job.id === savedJob?.job_id;
                      });
                    return (
                      <JobListingCard
                        key={job.id}
                        position={job.title_en}
                        name={job.company.full_name}
                        location={job.district.name_en}
                        experience={job.experience_level.name_en}
                        education={job.education_level.name_en}
                        gender={job.gender}
                        payment={job.currrency}
                        desc={
                          job &&
                          job.job &&
                          job.job.job_categories &&
                          job.job.job_categories[0] &&
                          job.job.job_categories[0].category
                            ? job.job.job_categories[0].category.title
                            : "No Category"
                        }
                        type={job.job_type}
                        date={job.date}
                        id={job.id}
                        image={job?.company?.img_url}
                        saved={saved}
                      />
                    );
                  })}
              </>
            )}
            <button
              className="view_button1"
              onClick={() => navigate("/joblisting")}
            >
              {" "}
              View More{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default JobDetails;
