import React, { useEffect, useState } from "react";
import "./RegisterCompany.css";
import heading from "../../assets/images/aboutus/Path 489.svg";
import Loader from "../../Components/Loader/Loader";
import mehnatilog from "../../assets/images/CompleteProfile/MEHNATI ILLUSTRATIONS-15.png";
import { FiCheck } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { CompanyRegister } from "../../Redux/Auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
import useDistrictsList from "../../hooks/useDistrictsList";
import useExpertiseList from "../../hooks/useExpertiseList";

function RegisterCompany() {
  const [pages, setpages] = useState(0);
  const handlenext = () => {
    setpages(pages + 1);
  };

  const dispatch = useDispatch();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [companyname, setcompanyname] = useState("");
  const [image, setimage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [Phone, setPhone] = useState("");
  const [district, setdistrict] = useState("");
  const [cities, setcities] = useState("");
  const [category, setcategory] = useState("");
  const [companysize, setcompanysize] = useState("");
  const [description, setdescription] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [facebook, setfacebook] = useState("");
  const [Website, setWebsite] = useState("");
  const [instagram, setinstagram] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirm, setconfirm] = useState(false);
  const [selectedactivity, setSelectedactivity] = useState("");
  const companysizelist = [
    { id: 1, name: "Self-employed", value: "Self-employed" },
    { id: 2, name: "1-10 employees", value: "1-10 employees" },
    { id: 3, name: "11-50 employees", value: "11-50 employees" },
    { id: 4, name: "51-200 employees", value: "51-200 employees" },
    { id: 5, name: "201-500 employees", value: "201-500 employees" },
    { id: 6, name: "501-1000 employees", value: "501-1000 employees" },
    { id: 7, name: "1001-5000 employees", value: "1001-5000 employees" },
    { id: 8, name: "5000+ employees", value: "5000+ employees" },
  ];

  const { result: activities, main: getActivities } = useAsync({
    fn: mehnatiApi.getActivities,
    onError: () => {
      notifyUtils.error("Failed to get activities");
    },
  });

  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);

  const handleImageSelected = (event) => {
    const imageInput = event.target;
    setSelectedImage(null);

    if (imageInput.files && imageInput.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage(e.target.result);
        setimage(imageInput.files[0]);
      };

      reader.readAsDataURL(imageInput.files[0]);
    }
  };

  const { districtsList } = useDistrictsList();
  const { expertiseList } = useExpertiseList();

  const handleDistrictChange = (e) => {
    setdistrict(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setcategory(e.target.value);
  };
  const handleActivityChange = (e) => {
    setSelectedactivity(e.target.value);
  };
  const handleCompanySizeChange = (e) => {
    setcompanysize(e.target.value);
  };
  useEffect(() => {
    getActivities();
  }, []);

  //disable button1
  const [disabled1, setdisabled1] = useState(true);
  const [disabled6, setdisabled6] = useState(true);
  const [disabled2, setdisabled2] = useState(true);
  const [disabled3, setdisabled3] = useState(true);
  const [disabled5, setdisabled5] = useState(true);
  const [agreeValue, setAgreeValue] = useState(false); // Initialize the state with an empty string

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?$/;
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+(\/\w+)*\/?$/;
  const websiteRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
  const instagramRegex = /^[a-zA-Z0-9._]{1,30}$/;

  // const passwordregex =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%^&*])[A-Za-z\d@$!#%^&*]{8,}$/;
  useEffect(() => {
    if (companyname === "" || Phone === "" || !selectedImage) {
      setdisabled1(true);
    } else {
      setdisabled1(false);
    }
  }, [selectedImage, companyname, Phone]);

  useEffect(() => {
    if (markerPosition) {
      setdisabled6(false);
    } else {
      setdisabled6(true);
    }
  }, [markerPosition]);

  //disable button2

  useEffect(() => {
    if (!category || !companysize || !district) {
      setdisabled2(true);
    } else {
      setdisabled2(false);
    }
  }, [cities, category, companysize, district]);

  //disable button3

  useEffect(() => {
    if (
      description === "" ||
      (linkedin !== "" && !linkedinRegex.test(linkedin)) ||
      (facebook !== "" && !facebookRegex.test(facebook)) ||
      (Website !== "" && !websiteRegex.test(Website))
    ) {
      setdisabled3(true);
    } else {
      setdisabled3(false);
    }
  }, [description, linkedin, facebook, Website, instagram]);

  //disable button5

  useEffect(() => {
    if (
      email === "" ||
      !emailRegex.test(email) ||
      // !passwordregex.test(password) ||
      password === "" ||
      selectedactivity === "" ||
      password !== confirm ||
      !agreeValue
    ) {
      setdisabled5(true);
    } else {
      setdisabled5(false);
    }
  }, [email, password, confirm, agreeValue]);

  const data = new FormData();
  data.append("full_name", companyname);
  data.append("email", email);
  data.append("password", password);
  data.append("district_id", district);
  data.append("city", cities);
  data.append("company_size", companysize);
  data.append("description", description);
  data.append("linked_in", linkedin);
  data.append("facebook", facebook);
  data.append("instagram", instagram);
  data.append("website", Website);
  data.append("category_id", category);
  data.append("img_url", image);
  data.append("phone_number", Phone);
  data.append("location_longitute", markerPosition?.lng);
  data.append("location_latitude", markerPosition?.lat);
  data.append("activity_id", selectedactivity);
  const handleregister = () => {
    dispatch(CompanyRegister(data));
    setAddToCardAction(true);
  };
  const { company, error, loading } = useSelector((state) => state.Auth);
  const [message_reg, setmessage_reg] = useState(false);
  useEffect(() => {
    if (company?.length > 0) {
      setmessage_reg(true);
    }
  }, [company]);

  const handlePaste = (event) => {
    event.preventDefault();

    // Access the clipboard data
    const clipboardData = event.clipboardData || window.clipboardData;

    // Get the pasted text from the clipboard
    const pastedText = clipboardData.getData("text/plain");

    // Do something with the pasted text (e.g., display an alert)
  };
  const [addToCardAction, setAddToCardAction] = useState(false);

  useEffect(() => {
    let actionDispatched = false;
    if (addToCardAction && !loading) {
      if (!actionDispatched && company && !error) {
        NotificationManager.success(
          "  You Have Successfuly Registerd Wait for the approval Email!",
        );
        actionDispatched = true;
      } else if (error) {
        NotificationManager.error(error);
      }
      setAddToCardAction(false);
    }
  }, [loading, company, error, addToCardAction]);

  return (
    <div className="Main_company_reg">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title">
            {" "}
            Companies Registration
          </div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>{" "}
            <span className="head_span">Companies Registration</span>
          </div>
        </div>
      </div>{" "}
      <div className="Main_company_reg_form">
        <div className="CompleteProfileStep1_container">
          <div className="CompleteProfileStep1_container_left">
            <img src={mehnatilog} alt="mehnatilog" className="mehnatilog" />
          </div>
          <div className="CompleteProfileStep1_container_right">
            <div className="CompleteProfileStep1_container_right_heading">
              <div className="CompleteProfileStep1_container_right_heading_title">
                {" "}
                <div className="tech">
                  <div className="backback">
                    {" "}
                    {(pages == 1 || pages == 2 || pages == 3 || pages == 4) && (
                      <div onClick={() => setpages(pages - 1)}>
                        <IoReturnUpBackSharp />
                      </div>
                    )}
                  </div>{" "}
                  Companies
                </div>
                <div>
                  {pages == 0 && (
                    <div className="Form_container">
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {companyname !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Full Name"
                          className={
                            companyname !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          value={companyname}
                          onChange={(e) => setcompanyname(e.target.value)}
                        />
                      </div>
                      <div className="profile_img_cont_2 ">
                        <div className="null_container_3">
                          {selectedImage ? (
                            <div>
                              <img
                                src={selectedImage}
                                alt="Selected Image Preview"
                                className="profile_img_prev"
                              />
                            </div>
                          ) : (
                            <>
                              {" "}
                              <div
                                id="image-preview"
                                className="text_cont_profile_pic"
                              ></div>
                              <div className="text_cont_profile_pic">
                                Company Logo
                              </div>
                              <div className="subtext_cont_profile_pic">
                                {"(500x500px )"}
                              </div>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          className="profile_img"
                          accept="image/*"
                          onChange={handleImageSelected}
                        />
                      </div>
                      <div className="complete_data_cont">
                        <PhoneInput
                          country={"lb"}
                          value={Phone}
                          onChange={(e) => setPhone(e)}
                          // className="input_complete_1"
                          className={
                            Phone !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                        />
                      </div>

                      <p className="allabove">All above fields are required*</p>
                      <div className="button_container" onClick={handlenext}>
                        <button
                          className="nextbutton"
                          type="submit"
                          disabled={disabled1}
                        >
                          {" "}
                          Next{" "}
                        </button>
                      </div>
                    </div>
                  )}
                  {pages == 1 && (
                    <div className="Form_container">
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {cities !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Cities"
                          // className="input_complete_1"
                          className={
                            cities !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          defaultValue={cities}
                          onChange={(e) => setcities(e.target.value)}
                        />
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {category !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <select
                          // className="input_complete_2"
                          className={
                            category !== ""
                              ? "input_complete_2_checked"
                              : "input_complete_2"
                          }
                          required
                          onChange={handleCategoryChange}
                          defaultValue={category}
                        >
                          <option value="" selected disabled>
                            Select Category
                          </option>
                          {expertiseList &&
                            expertiseList.map((item) => (
                              <option value={item.id}>{item.name_en}</option>
                            ))}
                        </select>
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {selectedactivity !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <select
                          // className="input_complete_2"
                          className={
                            !selectedactivity
                              ? "input_complete_2_checked"
                              : "input_complete_2"
                          }
                          required
                          onChange={handleActivityChange}
                          defaultValue={selectedactivity}
                        >
                          <option value="" selected disabled>
                            Select Activity
                          </option>
                          {activities &&
                            activities.map((item) => (
                              <option value={item.id}>{item.title}</option>
                            ))}
                        </select>
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {district !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <select
                          // className="input_complete_2"
                          className={
                            district !== ""
                              ? "input_complete_2_checked"
                              : "input_complete_2"
                          }
                          required
                          onChange={handleDistrictChange}
                          defaultValue={district}
                        >
                          <option value="" selected disabled>
                            {" "}
                            Address By District{" "}
                          </option>
                          {districtsList &&
                            districtsList.map((item) => (
                              <option value={item.id}>{item.name_en}</option>
                            ))}
                        </select>
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {companysize !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <select
                          // className="input_complete_2"
                          className={
                            companysize !== ""
                              ? "input_complete_2_checked"
                              : "input_complete_2"
                          }
                          required
                          onChange={handleCompanySizeChange}
                          defaultValue={companysize}
                        >
                          <option value="" selected disabled>
                            {" "}
                            Company Size
                          </option>
                          {companysizelist &&
                            companysizelist.map((item) => (
                              <option value={item.value}>{item.name}</option>
                            ))}
                        </select>
                      </div>

                      <p className="allabove">All above fields are required*</p>
                      <div className="button_container" onClick={handlenext}>
                        <button
                          className="nextbutton"
                          type="submit"
                          disabled={disabled2}
                        >
                          {" "}
                          Next{" "}
                        </button>
                      </div>
                    </div>
                  )}

                  {pages == 2 && (
                    <div className="Form_container">
                      <div className="complete_data_cont">
                        <textarea
                          type="text"
                          placeholder="Description"
                          className={
                            description !== ""
                              ? "input_complete_textarea_check"
                              : "input_complete_textarea"
                          }
                          required
                          onChange={(e) => setdescription(e.target.value)}
                          defaultValue={description}
                        />
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {linkedin !== "" && linkedinRegex.test(linkedin) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Linkedin ex : https://www.linkedin.com/in/example-example/"
                          className={
                            linkedin !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setlinkedin(e.target.value)}
                          defaultValue={linkedin}
                        />
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {Website !== "" && websiteRegex.test(Website) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Website ex : www.example.com"
                          className={
                            Website !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setWebsite(e.target.value)}
                          defaultValue={Website}
                        />
                      </div>
                      <div className="complete_data_cont">
                        <div className="null_container">
                          {facebook !== "" && facebookRegex.test(facebook) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Facebook ex : https://www.facebook.com/example/"
                          className={
                            facebook !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setfacebook(e.target.value)}
                          defaultValue={facebook}
                        />
                      </div>

                      <div className="complete_data_cont">
                        <div className="null_container">
                          {instagram !== "" && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Instagram ex : www.instagram.com/example"
                          className={
                            instagram !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setinstagram(e.target.value)}
                          defaultValue={instagram}
                        />
                      </div>
                      <div className="button_container" onClick={handlenext}>
                        <button className="nextbutton" disabled={disabled3}>
                          {" "}
                          Next{" "}
                        </button>
                      </div>
                    </div>
                  )}
                  {pages == 3 && (
                    <div className="Form_container">
                      <div className="complete_data_cont">
                        <div className="Map_container">
                          <LoadScript googleMapsApiKey="AIzaSyAEmTg3qjxS9QYc7YcWggM_YiqX6QW7DDU">
                            <GoogleMap
                              className="Map"
                              center={{ lat: 33.8938, lng: 35.5018 }}
                              zoom={14}
                              mapContainerStyle={{
                                height: "300px",
                                width: "550px",
                              }}
                              onClick={(event) => {
                                setMarkerPosition({
                                  lat: event.latLng.lat(),
                                  lng: event.latLng.lng(),
                                });
                              }}
                            >
                              {markerPosition && (
                                <Marker
                                  position={markerPosition}
                                  draggable={true}
                                  onDragEnd={(event) => {
                                    setMarkerPosition({
                                      lat: event.latLng.lat(),
                                      lng: event.latLng.lng(),
                                    });
                                  }}
                                />
                              )}
                            </GoogleMap>
                          </LoadScript>
                        </div>
                      </div>

                      <div className="button_container" onClick={handlenext}>
                        <button
                          className="nextbutton"
                          type="submit"
                          disabled={disabled6}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {pages == 4 && (
                    <div className="Form_container">
                      <NotificationContainer />
                      <div className="signup_data">
                        <div className="null_container">
                          {email !== "" && emailRegex.test(email) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type="email"
                          placeholder="Email Address"
                          // className="input_complete_1"
                          className={
                            email !== ""
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setemail(e.target.value)}
                          defaultValue={email}
                        />
                      </div>
                      <div className="signup_data">
                        <div className="null_container">
                          {password !== "" && password === confirm && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type={showpassword ? "text" : "password"}
                          placeholder="Password"
                          // className="input_complete_1"
                          className={
                            password !== "" && password === confirm
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setpassword(e.target.value)}
                          onPaste={handlePaste}
                        />
                        {!showpassword && (
                          <div
                            className="eye"
                            onClick={() => setshowpassword(!showpassword)}
                          >
                            <AiFillEye size={30} />
                          </div>
                        )}
                        {showpassword && (
                          <div
                            className="eye"
                            onClick={() => setshowpassword(!showpassword)}
                          >
                            <AiFillEyeInvisible size={30} />
                          </div>
                        )}
                      </div>

                      <div className="signup_data">
                        <div className="null_container">
                          {confirm !== "" && password === confirm && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        <input
                          type={showpassword1 ? "text" : "password"}
                          placeholder="Confirm Password"
                          // className="input_complete_1"
                          className={
                            confirm !== "" && password === confirm
                              ? "input_complete_1_check"
                              : "input_complete_1"
                          }
                          required
                          onChange={(e) => setconfirm(e.target.value)}
                          onPaste={handlePaste}
                        />
                        {!showpassword1 && (
                          <div
                            className="eye"
                            onClick={() => setshowpassword1(!showpassword1)}
                          >
                            <AiFillEye size={30} />
                          </div>
                        )}
                        {showpassword1 && (
                          <div
                            className="eye"
                            onClick={() => setshowpassword1(!showpassword1)}
                          >
                            <AiFillEyeInvisible size={30} />
                          </div>
                        )}
                      </div>
                      {/* <div className="dateofbirth1">
                        The Password should contain : <br></br>
                        At least 8 characters in length<br></br>At least one
                        uppercase letter<br></br>At least one lowercase letter
                        <br></br>At least one digit<br></br>At least one special
                        character
                      </div> */}

                      <div className="agree_cont">
                        <input
                          type="checkbox"
                          name="agree"
                          id="agree"
                          required
                          onChange={(e) => setAgreeValue(e.target.checked)}
                        />
                        <p className="agree"> I agree to the terms. </p>
                      </div>

                      <div
                        className="button_container"
                        onClick={handleregister}
                      >
                        <button
                          className={
                            disabled5 ? "nextbuttondisable" : "nextbutton"
                          }
                          type="submit"
                          disabled={disabled5}
                        >
                          {" "}
                          Sign Up{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterCompany;
