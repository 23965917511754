import axios from "axios";
import { UserActions } from "./UserReducers";
import { AuthActions } from "../Auth/AuthReducers";

export const getmyinfo = () => async (dispatch) => {
  try {
    dispatch(UserActions.infoReq());
    const token = localStorage.getItem("token");
    const res = await axios.get(process.env.REACT_APP_API + "/user/myInfo", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(UserActions.infosucess(res.data.data));
  } catch (error) {
    // token expired should logout user (idk how much is that accurate will refactor this thing today)
    dispatch(AuthActions.logout());
    dispatch(UserActions.infoFail(error));
  }
};
export const editmyinfo = (data) => async (dispatch) => {
  try {
    dispatch(UserActions.infoeditReq());
    const token = localStorage.getItem("token");
    const res = await axios.put(process.env.REACT_APP_API + "/user/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(UserActions.infoeditsucess(res.data.data));
  } catch (error) {
    dispatch(UserActions.infoeditFail(error));
  }
};
export const editmyimg = (imgs) => async (dispatch) => {
  try {
    dispatch(UserActions.imgeditreuqest());
    const token = localStorage.getItem("token");

    const res = await axios.put(
      process.env.REACT_APP_API + "/user/assigne",
      imgs,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(UserActions.imgeditsucess(res.data.data));
  } catch (error) {
    dispatch(UserActions.imgeditfail(error));
  }
};
