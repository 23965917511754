import React, { useState, useEffect } from "react";
import "./Home.css";
import dashboard from "../../assets/images/home/dashboardBg.webp";
import redbg from "../../assets/images/home/pngs-83.png";
import yellowbg from "../../assets/images/home/pngs-81.png";
import greenbg from "../../assets/images/home/pngs-82.png";
import purple from "../../assets/images/home/pngs-80.png";
import SucessStories from "../../Components/SucessStories/SucessStories";
import JobsCard from "../../Components/JobsCard/JobsCard";
import suitcase1 from "../../assets/images/home/suitcase.webp";
import Section5 from "../../assets/images/home/section5.webp";
import CategorieCard from "../../Components/CategorieCard/CategorieCard";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
import useJobs from "../../hooks/useJobs";

export default function Home(route) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchdata = searchParams.get("search");

  const { getJobs, jobs } = useJobs();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
    },
  };

  const { result: successStories, main: getSuccessStories } = useAsync({
    fn: mehnatiApi.getSuccessStories,
    onError: () => {
      notifyUtils.error("Error getting success stories!");
    },
  });

  const {
    result: categories,
    main: getCategories,
    loading: loadingCategories,
  } = useAsync({
    fn: mehnatiApi.getCategories,
    onError: () => {
      notifyUtils.error("Error getting categories!");
    },
  });

  const { main: getCompanyStatistics, result: companyStatistics } = useAsync({
    fn: mehnatiApi.getCompanyStatistics,
    onError: () => {
      notifyUtils.error("Error getting company statistics!");
    },
  });

  const { result: homepageCompanies, main: getHomepageCompanies } = useAsync({
    fn: mehnatiApi.getHomepageCompanies,
    onError: () => {
      notifyUtils.error("Error getting companies!");
    },
  });

  useEffect(() => {
    getHomepageCompanies();
    getCompanyStatistics();
    getCategories();
    getSuccessStories();
  }, []);

  useEffect(() => {
    getJobs({
      search: searchdata,
    });
  }, [searchdata]);

  useEffect(() => {
    if (searchdata) {
      window.scrollTo(0, 0); // Scroll to top of the page
    }
  }, [searchdata]);
  //initialize dd with 6 usestate
  const [dd, setdd] = useState(6);
  //function that check if dd is 6 setdd100 else set it 6
  const checkdd = () => {
    if (dd == 6) {
      setdd(100);
    } else {
      setdd(6);
    }
  };

  return (
    <div className="Main_home_div">
      <div className="home_section_cont">
        {searchdata && (
          <div className="home_section2">
            {jobs && jobs?.length > 0 ? (
              jobs.map((item) => (
                <JobsCard
                  image={item.company?.img_url}
                  status={item.job_type}
                  date={item.date}
                  name={item.title_en}
                  text={item.description_en}
                  id={item.id}
                />
              ))
            ) : (
              <p className="nomatch">
                Oops ... We didnt find anything that matches this search
              </p>
            )}
          </div>
        )}

        <div className="home_career">
          <div className="home_career_text_cont">
            <div className="home_career_title">
              Start your career journey with us
            </div>
          </div>
          <div className="home_career_img_container">
            <img src={dashboard} alt="dashboard" className="home_career_img" />
          </div>
        </div>
        <div className="home_stats">
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={redbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {companyStatistics?.company}
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={yellowbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {companyStatistics?.job}
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={greenbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {companyStatistics?.user}
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">35</div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_section2_cont">
        <div className="home_career_text_cont">
          <div className="home_career_title">Success Stories</div>
        </div>
        <div className="home_section2">
          {successStories &&
            successStories
              .slice(1, 4)
              .map((item) => (
                <SucessStories
                    onClick={()=>{
                      navigate('/successstories')
                    }}
                  img={item?.img_url}
                  status={item.status}
                  date={item.time}
                  name={item.full_name}
                  text={item.information}
                  position={item.title}
                />
              ))}
        </div>
      </div>
      <div className="home_section3_cont">
        <div className="home_career_text_cont">
          <div className="home_career_title">
            <img src={suitcase1} className="icon_logo" />
            Recent Jobs
          </div>
        </div>
        <div className="home_section2">
          {jobs &&
            jobs?.slice(0, 3).map((item) => {
              return (
                <JobsCard
                  image={item.company?.img_url}
                  status={item.job_type}
                  date={item.date}
                  name={item.title_en}
                  text={item.description_en}
                  id={item.id}
                />
              );
            })}
          {/* <JobsCard
            img={dashboard}
            status={"Internship"}
            date={"07-06-2022"}
            name={"Accountant"}
            text={
              "information about the success story information about the success story"
            }
          /> */}
        </div>
        <div
          className="home_section3_button"
          onClick={() => navigate("/joblisting")}
        >
          <div className="home_section3_button_text">See All Jobs</div>
        </div>
      </div>
      <div className="home_section4_cont">
        <div className="home_section4_1">
          <div className="home_career_text_cont_4">
            <div className="home_career_title_sec4"> Are you a job seeker?</div>
            <div className="home_career_subtitle_sec4">
              {" "}
              Search, Apply & Get a Job
            </div>
            <div className="home_career_title2_sec4"> Join Us Now</div>
            <div className="home_section4_button">
              <div
                className="home_section4_button_text"
                onClick={() => navigate("/completeprofile")}
              >
                Register
              </div>
            </div>
          </div>
        </div>
        <div className="home_section4_2">
          {" "}
          <div className="home_career_text_cont_4">
            <div className="home_career_title_sec4"> Are you an employer?</div>
            <div className="home_career_subtitle_sec4">
              {" "}
              Find and hire Professionals
            </div>
            <div className="home_career_title2_sec4"> Join Us Now</div>
            <div className="home_section4_button">
              <div
                className="home_section4_button_text"
                onClick={() => navigate("/registercompany")}
              >
                Register
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_section5_cont">
        <div className="home_section5_cont_all">
          <div className="home_section5_text_container">
            <div className="home_section5_title">
              Create a Professional CV Quickly and Easily!{" "}
            </div>
            <div
              className="home_section5_button_cont"
              onClick={() => navigate("/cvbuilder")}
            >
              <div className="home_section5_button"> Create your CV </div>
            </div>
            <div className="home_section5_subtitle">
              If you already have a CV upload it here
            </div>
            <div
              className="home_section5_button_cont"
              onClick={() => navigate("/profile")}
            >
              <div className="home_section5_button"> Upload your CV </div>
            </div>
          </div>
          <div className="home_section5_img_container">
            <img src={Section5} className="home_section5_img" />
          </div>
        </div>
      </div>
      <div className="home_section6_cont">
        {" "}
        <div className="home_career_text_cont">
          <div className="home_cat_title">
            <img src={suitcase1} className="icon_logo" />
            Popular Categories
          </div>
        </div>
        <div className="cat_all">
          {categories
            ?.slice(0, dd)
            .sort((a, b) => b.job_category_count - a.job_category_count) // Sort in descending order
            .map((cat) => (
              <CategorieCard
                key={cat.id}
                img={`${process.env.REACT_APP_API}/category/${cat.image}`}
                title={cat.title}
                desc={cat.job_category_count}
                id={cat.id}
              />
            ))}
        </div>
        <div className="cat_button" onClick={() => checkdd()}>
          {dd == 6 ? <div>See All Categories</div> : <div>See Less</div>}
        </div>
      </div>

      <div className="home_section7_cont">
        <div className="home_section7_cont_title"> Companies</div>

        {homepageCompanies && (
          <Carousel
            responsive={responsive}
            showThumbs={false}
            showDots={false}
            autoPlay={true}
            arrows={false}
            draggable={true}
            swipeScrollTolerance={50}
            centerMode={true}
            containerClass="carousel-item1"
            autoPlaySpeed={3000}
          >
            {homepageCompanies &&
              homepageCompanies
                ?.slice(0, homepageCompanies?.length / 2)
                .map((camp) => (
                  <div key={camp.id}>
                    <img
                      src={
                        process.env.REACT_APP_API + "/company/" + camp?.img_url
                      }
                      className="companies_img"
                      alt={`Company ${camp.id}`}
                    />
                  </div>
                ))}
          </Carousel>
        )}

        <br />

        {homepageCompanies && (
          <Carousel
            responsive={responsive}
            showThumbs={false}
            showDots={false}
            autoPlay={true}
            arrows={false}
            draggable={true}
            swipeScrollTolerance={50}
            centerMode={true}
            containerClass="carousel-item1"
            autoPlaySpeed={3000}
          >
            {homepageCompanies
              ?.slice(homepageCompanies?.length / 2, homepageCompanies?.length)
              .map((camp) => (
                <div key={camp.id}>
                  <img
                    src={
                      process.env.REACT_APP_API + "/company/" + camp?.img_url
                    }
                    className="companies_img"
                    alt={`Company ${camp.id}`}
                  />
                </div>
              ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}
