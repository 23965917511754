import React, { useState, useEffect } from "react";
import "./Profile.css";
import "./../../Components/Profile/ProfileCard.css";
import "./../../Components/Profile/Applied_jobs.css";
import "./../../Components/Profile/SavedJobs.css";
import heading from "../../assets/images/profile/Path 489.png";
import red_icon from "../../assets/images/home/Path 191.webp";
import edit from "../../assets/images/profile/edit.png";
import ProfileCard from "../../Components/Profile/ProfileCard";
import SavedJobs from "../../Components/Profile/SavedJobs";
import Applied_Jobs from "../../Components/Profile/Applied_jobs";
import { useDispatch, useSelector } from "react-redux";
import { getmyinfo } from "../../Redux/User/UserActions";
import EditProfile from "../EditProfile/EditProfile";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import isAuth from "../../Utils/isAuth";
import useSavedJobs from "../../hooks/useSavedJobs";
import useJobs from "../../hooks/useJobs";
import useAuthAndFetchSavedJobs from "../../hooks/useAuthAndFetchSavedJobs";
function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userinfo, loading } = useSelector((store) => store.User);

  const { savedJobs, getSavedJobs } = useAuthAndFetchSavedJobs();

  const { unsaveJob } = useSavedJobs({
    onUnsaveSuccess: () => {
      getSavedJobs();
    },
  });

  const { appliedJobs, loadingAppliedJobs, getAppliedJobs } = useJobs();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getmyinfo());
    getAppliedJobs();
  }, []);

  const [editprofile, seteditprofile] = useState(false);
  const handleeditopen = () => {
    seteditprofile(!editprofile);
  };

  const handleClick = (jobId) => {
    navigate(`/jobdetails/${jobId}`);
  };

  return (
    <div className="Main_profile_cont">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>
      </div>
      <div className="profile_cont">
        <div className="profile_img_cont">
          <div className="profile_img_pro">
            {" "}
            <img src={red_icon} alt="red_icon" className="red_icon" />
            <img
              src={process.env.REACT_APP_API + "/user/" + userinfo?.img_url}
              alt="cover"
              className="cover1"
            />
          </div>
          <div className="edit_cont" onClick={handleeditopen}>
            <img src={edit} alt="edit" className="edit" />
          </div>{" "}
        </div>
      </div>
      {editprofile ? (
        <EditProfile seteditprofile={seteditprofile} />
      ) : (
        <>
          {loading ? (
            <div className="loader_container">
              <Loader />
            </div>
          ) : (
            <div className="profile_info">
              <div className="profile_info_cont">
                <div className="profile_info_cont_text1">
                  {userinfo?.full_name}
                </div>
                <div className="profile_info_cont_text">{userinfo?.email}</div>
                <div className="profile_info_cont_text">
                  {userinfo?.user_expertises?.[0]?.expertise?.name_en}
                </div>
              </div>

              <div className="Doc_text"> Documents </div>

              <div className="Doc_info">
                <ProfileCard
                  className="CURRICULUM_VITEA"
                  buttontext="Curricculum Vitae"
                  date="Updated 07-04-2022"
                  text={`${userinfo?.full_name}'s CV`}
                  subtitle="Your CV"
                  classNameB="PROFILE_CARD_BUTTON b1"
                  link={process.env.REACT_APP_API + "/user/" + userinfo?.cv}
                />

                <ProfileCard
                  className="COVER_LETTER"
                  buttontext="Cover Letter"
                  date="Updated 01-04-2022"
                  text={`${userinfo?.full_name}'s Cover Letter`}
                  subtitle="Your Cover Letter"
                  classNameB="PROFILE_CARD_BUTTON b2"
                  link={
                    process.env.REACT_APP_API +
                    "/user/" +
                    userinfo?.cover_letter
                  }
                />

                <ProfileCard
                  className="CERTIFICATES"
                  buttontext="Certificate"
                  date="Updated 07-04-2022"
                  text={`${userinfo?.full_name}'s Certificates`}
                  subtitle="Your Certificates"
                  classNameB="PROFILE_CARD_BUTTON b3"
                  link={
                    process.env.REACT_APP_API + "/user/" + userinfo?.certificate
                  }
                />
              </div>

              <div className="JOB_TEXT"> Saved Jobs </div>

              <div className="jobs_info">
                {savedJobs?.length === 0 ? (
                  <div className="title">
                    <div className="title_text">No Saved Jobs</div>
                  </div>
                ) : (
                  <>
                    {savedJobs?.map((job) => (
                      <SavedJobs
                        onUnsave={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to unsave this job"
                            )
                          ) {
                            unsaveJob({
                              id: job.job_id,
                            });
                          }
                        }}
                        position={job?.job?.title_en}
                        date={job?.job?.date}
                        name={job?.job?.company.full_name}
                        location={job?.job?.district.name_en}
                        experience={job?.job?.experience_level.name_en}
                        education={job?.job?.education_level.name_en}
                        gender={job?.job?.gender}
                        payment={job?.job?.currrency}
                        type={job?.job?.job_type}
                        id={job?.id}
                        jobId={job?.job_id}
                        image={job?.job?.company?.img_url}
                      />
                    ))}
                  </>
                )}

                {/* <SavedJobs
                  position="Marketing Manager"
                  name="Zaatar w Zeit"
                  location="Location"
                  experience="Experience Level"
                  education="Education Level"
                  gender="Gender"
                  payment="LBP-$"
                  desc="Business, Management and Administration"
                /> */}
                {savedJobs?.length !== 0 && (
                  <button
                    className="view_button1"
                    onClick={() => navigate(`/savedjoblist`)}
                  >
                    {" "}
                    View{" "}
                  </button>
                )}
              </div>

              <div className="Main_Applied_jobs">
                <div className="Applied_jobs_text"> Applied Jobs </div>
                <div className="Applied_Jobs_cont">
                  {loadingAppliedJobs ? (
                    <div className="loader_container">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {appliedJobs &&
                        appliedJobs?.map((job) => (
                          <Applied_Jobs
                            id={job.id}
                            position={job?.job?.title_en}
                            location={job?.job?.district.name_en}
                            createdDate={job?.createdAt}
                            buttontext={job?.status}
                            reason={job?.reason}
                            className="HIRE"
                            image={job?.job?.company?.img_url}
                            onClick={() => handleClick(job.job_id)}
                          />
                        ))}
                    </>
                  )}

                  <button
                    className="view_button"
                    onClick={() => navigate("/appliedjobslist")}
                  >
                    {" "}
                    More{" "}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Profile;
