import { combineReducers } from "redux";

import AuthSlice from "./Auth/AuthReducers";
import UserSlice from "./User/UserReducers";

const RootReducers = combineReducers({
  Auth: AuthSlice.reducer,
  User: UserSlice.reducer,
});

export default RootReducers;
