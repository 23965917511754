import "./style.css";

export default function ErrorBoundaryPage() {
  return (
    <main className="main-container">
      <div className="text-center">
        <h1 className="page-title">Oops! Something went wrong.</h1>
        <p className="error-message">
          An unexpected error occurred while loading the page. Don’t worry, it’s
          not your fault. Please try refreshing the page, or use one of the
          options below to get back on track.
        </p>
        <div className="action-buttons">
          <a href="/" className="home-button">
            Return to Homepage
          </a>
          <a href="/contactus" className="support-link">
            Contact Support →
          </a>
        </div>
      </div>
    </main>
  );
}
