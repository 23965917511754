import React, { useState, useEffect } from "react";
import "./CvBuilder.css";
import heading from "../../assets/images/contactus/headingc.png";
import moment from "moment";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";

function CvBuilder() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [address, setaddress] = useState("");
  const [school, setschool] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [profile, setprofile] = useState([]);
  const [education, setEducation] = useState([
    {
      school: "",
      degree: "",
      startDate: "",
      endDate: "",
      present: false,
      specialization: "",
      location: "",
    },
  ]);
  const [fields, setfields] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [experiences, setExperiences] = useState([
    {
      title: "",
      company: "",
      startDate: "",
      endDate: "",
      present: false,
      description: [],
      location: "",
    },
  ]);
  const [skills, setSkills] = useState([{ id: 1, name: "" }]);
  const [languages, setLanguages] = useState([
    { id: 1, name: "", proficiency: "" },
  ]);
  const [activities, setActivities] = useState([{ id: 1, name: "" }]);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  function handleEmailChange(event) {
    const newEmail = event.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  }


  const handlefields = () => {
    if (
      FirstName !== "" &&
      LastName !== "" &&
      address !== "" &&
      email !== "" &&
      phone !== "" &&
      education[0].school !== "" &&
      education[0].degree !== "" &&
      education[0].startDate !== "" &&
      languages[0].name !== "" &&
      languages[0].proficiency !== "" &&
      skills[0].name !== "" &&
      activities[0].name !== ""
    ) {
      setfields(false);
      createCV({
        first_name: FirstName,
        last_name: LastName,
        location: address,
        // objetive: profile.replace(/\n/g, " "),
        school_name: education[0].school,
        objective: profile
          .split("-")
          .map((duty) => duty.trim().replace(/\n/g, ""))
          .filter((duty) => duty !== ""),
        email: email,
        phone: phone,
        education: [
          ...education.map((edu) => ({
            school_name: edu.school,
            degree: edu.degree,
            specialization: edu.specialization,
            location: edu.location,
            period: {
              start: moment(edu.startDate).format("DD/MM/YYYY"),
              end: edu.endDate
                ? moment(edu.endDate).format("DD/MM/YYYY")
                : "Present",
            },
          })),
        ],
        languages: [
          ...languages.map((lang) => ({
            language_name: lang.name,
            level: lang.proficiency,
          })),
        ],
        experience: [
          ...experiences.map((exp) => ({
            position: exp.title,
            company_name: exp.company,
            location: exp.location,
            period: {
              start: moment(exp.startDate).format("DD/MM/YYYY"),
              end: exp.present
                ? "Present"
                : moment(exp.endDate).format("DD/MM/YYYY"),
            },

            main_duties: exp.description,
          })),
        ],
        skill: [
          ...skills.map((skill) => ({
            skill: skill.name,
          })),
        ],
        activities: [
          ...activities.map((activity) => ({
            activity_name: activity.name,
          })),
        ],
      });
    } else {
      setfields(true);
    }
  };

  const {
    main: createCV,
    result: cv,
    loading: loadingCreateCV,
  } = useAsync({
    fn: mehnatiApi.createCV,
    onSuccess: () => {
      notifyUtils.success("CV created successfully");
    },
    onError: () => {
      notifyUtils.error("Failed to create CV");
    },
  });

  const handleAddSection = () => {
    if (education.length < 4) {
      setEducation([
        ...education,
        {
          school: "",
          degree: "",
          startDate: "",
          endDate: "",
          present: false,
          specialization: "",
          location: "",
        },
      ]);
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value, checked, type } = event.target;
    const list = [...education];

    list[index][name] = type === "checkbox" ? checked : value;

    // Handle checkbox logic
    if (type === "checkbox") {
      if (checked) {
        list[index].present = true;
        list[index].endDate = "";
      } else {
        list[index].present = false;
      }
    }

    // Validate dates
    const today = new Date();

    if (name === "startDate" || name === "endDate") {
      const startDate = new Date(list[index].startDate);
      const endDate = new Date(list[index].endDate);

      // Check if startDate is in the future
      if (name === "startDate" && startDate > today) {
        notifyUtils.error("Start date cannot be in the future.");
        list[index].startDate = ""; // Reset startDate if invalid
      }

      // Check if endDate is in the future
      if (name === "endDate" && endDate > today) {
        notifyUtils.error("End date cannot be in the future.");
        list[index].endDate = ""; // Reset endDate if invalid
      }

      // Check if endDate is before startDate
      if (name === "endDate" && startDate > endDate) {
        notifyUtils.error("End date cannot be before start date.");
        list[index].endDate = ""; // Reset endDate if invalid
      }

      // Check if startDate is after endDate (if both are set)
      if (name === "startDate" && endDate && startDate > endDate) {
        notifyUtils.error("Start date cannot be after end date.");
        list[index].startDate = ""; // Reset startDate if invalid
      }
    }

    setEducation(list);
  };

  const handleRemoveSection = (index) => {
    const list = [...education];
    list.splice(index, 1);
    setEducation(list);
  };
  //experience section


  const handleexperienceAddSection = () => {
    if (experiences.length < 4) {
      setExperiences([
        ...experiences,
        {
          title: "",
          company: "",
          startDate: "",
          endDate: "",
          present: false,
          description: [],
          location: "",
        },
      ]);
    }
  };

  const handleProfile = (event) => {
    const { value } = event.target;

    if (event.nativeEvent.inputType === "insertLineBreak") {
      // User pressed Enter key, add bullet point
      const lines = value.split("\n");
      const newLines = lines.map((line) => {
        if (line.trim() === "") {
          return line;
        } else {
          return `-${line.trim()}`;
        }
      });
      setprofile(newLines.join("\n"));
    } else {
      // User typed text without pressing Enter key
      let newValue = value.replace(/(\n|^)-/g, "\n");

      // Check if last character is a dash and remove it if it is
      if (newValue.charAt(newValue.length - 1) === "-") {
        newValue = newValue.slice(0, -1);
      }

      setprofile(newValue);
    }
  };
  const handleInputChangeexperience = (index, event) => {
    const { name, value, type, checked } = event.target;
    const list = [...experiences];

    if (type === "checkbox") {
      list[index][name] = checked;
      if (checked) {
        list[index].endDate = "";
      }
    } else if (name === "description") {
      if (event.nativeEvent.inputType === "insertLineBreak") {
        // User pressed Enter key, add bullet point
        const lines = value.split("\n");
        const newLines = lines.map((line) => {
          if (line.trim() === "" || line.trim().startsWith("-")) {
            return line;
          } else {
            return `-${line.trim()}`;
          }
        });
        list[index][name] = newLines.join("\n");
      } else {
        // User typed text without pressing Enter key
        list[index][name] = value.replace(/(\n|^)- /g, "\n");
      }
    } else {
      list[index][name] = value;
    }

    setExperiences(list);
  };

  const handleRemoveSectionexperience = (index) => {
    const list = [...experiences];
    list.splice(index, 1);
    setExperiences(list);
  };
  //skills section


  const handleAddSkill = () => {
    if (skills.length < 10) {
      const newSkills = [...skills];
      const id = skills[skills.length - 1].id + 1;
      newSkills.push({ id, name: "" });
      setSkills(newSkills);
    }
  };

  const handleSkillChange = (event, id) => {
    const newSkills = [...skills];
    const index = newSkills.findIndex((skill) => skill.id === id);
    newSkills[index].name = event.target.value;
    setSkills(newSkills);
  };

  const handleRemoveSkill = (id) => {
    const newSkills = skills.filter((skill) => skill.id !== id);
    for (let i = 0; i < newSkills.length; i++) {
      newSkills[i].id = i + 1;
    }
    setSkills(newSkills);
  };
  //languages


  const handleAddLanguage = () => {
    if (languages.length < 10) {
      const newLanguages = [...languages];
      const id = languages[languages.length - 1].id + 1;
      newLanguages.push({ id, name: "", proficiency: "" });
      setLanguages(newLanguages);
    }
  };

  // const handleLanguageChange = (event, id, key) => {
  //   const newLanguages = [...languages];
  //   const index = newLanguages.findIndex((language) => language.id === id);
  //   newLanguages[index][key] = event.target.value;
  //   setLanguages(newLanguages);
  // };
  const handleLanguageChange = (event, id, key) => {
    const newLanguages = [...languages];
    const index = newLanguages.findIndex((language) => language.id === id);

    if (key === "name") {
      newLanguages[index][key] = event.target.value;
    } else if (key === "proficiency") {
      newLanguages[index][key] = event.currentTarget.dataset.value;
    }

    setLanguages(newLanguages);
  };

  // const handleRemoveLanguage = (id, event) => {
  //   event.preventDefault();
  //   const newLanguages = languages.filter((language) => language.id !== id);
  //   setLanguages(newLanguages);
  // };
  const handleRemoveLanguage = (id) => {
    const newLanguages = languages.filter((language) => language.id !== id);

    // Update IDs of remaining language objects
    for (let i = 0; i < newLanguages.length; i++) {
      newLanguages[i].id = i + 1;
    }

    setLanguages(newLanguages);
  };

  //activities



  const handleAddActivity = () => {
    if(FirstName == "" && LastName == "" && address == "" && school == "" && email == ""
    && phone == "" && profile.length == 0 && education.length < 2 && experiences.length < 2 && skills.length < 2) {
      notifyUtils.error("Please make sure you filled one of the required fields before adding activities")
      return
    }

    if (activities.length < 4) {
      const newActivities = [...activities];
      const id = activities[activities.length - 1].id + 1;
      newActivities.push({ id, name: "" });
      setActivities(newActivities);
    }
  };

  const handleActivityChange = (event, id) => {
    const newActivities = [...activities];
    const index = newActivities.findIndex((activity) => activity.id === id);
    newActivities[index].name = event.target.value;
    setActivities(newActivities);
  };

  const handleRemoveActivity = (id) => {
    const newActivities = activities.filter((activity) => activity.id !== id);
    for (let i = 0; i < newActivities.length; i++) {
      newActivities[i].id = i + 1;
    }
    setActivities(newActivities);
  };

  //disable view button
  const [disabled, setdisabled] = useState("");
  //personal info
  useEffect(() => {
    if (
      FirstName === "" ||
      LastName === "" ||
      address === "" ||
      school === "" ||
      email === "" ||
      phone === "" ||
      profile === "" ||
      education[0].school === "" ||
      education[0].degree === "" ||
      education[0].startDate === "" ||
      languages[0].name === "" ||
      languages[0].proficiency == "" ||
      skills[0].name === "" ||
      activities[0].name === ""
    ) {
      setdisabled(true);
      setError1("Please fill in the required fields ***");
    } else {
      setdisabled(false);
      setError1("");
    }
  }, [
    FirstName,
    LastName,
    address,
    school,
    email,
    phone,
    profile,
    education,
    languages,
    experiences,
    skills,
    activities,
  ]);

  const personalInfoRegex = /^[a-zA-Z]+$/;
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  useEffect(() => {
    if (emailRegex.test(email) || email === "") {
      setError("");
    } else {
      setError("Please enter a valid email address");
    }
  }, [email]);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddSkill();
    }
  };

  const handleActivityKeyPress = (event, id) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent page refresh
      handleAddActivity(); // Optionally add a new activity
    }
  };
  return (
    <div className="Main_div_cv">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>
        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Create Your CV</div>

          <div className="Aboutus_heading_text_desc">
            <a href="/">Home /</a>
            <span className="head_span">Cv Builder</span>
          </div>
        </div>
      </div>
      <div className="cv_container">
        <div className="cv_head">
          <div className="cv_title">Curriculum Vitae (CV) </div>
        </div>
        <div className="underline_cv" />
        <div className="cv_title_text">
          Now you can create your CV in a few minutes
        </div>
        <div className="underline_cv" />
        <div className="cv_text_conts">
          <div className="cv_text_cont">
            <div className="cv_text_title2">The context</div>
            <div className="cv_text_contents">
              The Mehnati platform aims to help you concretely enter the job
              market. As the step of creating a CV can be one of the most
              complicated at the beginning of a career, our experts have decided
              to accompany you in this step by creating a special page on the
              site through which you will get your CV. And now you ve arrived on
              this page!
            </div>
          </div>
          <div className="cv_text_cont">
            <div className="cv_text_title2">How to use this tool</div>
            <div className="cv_text_contents">
              Simply fill in the fields with your personal information, then
              upload the content to get your CV in a few moments. You can then
              print it out or send it by email to apply to any of the companies
              you are interested in.
            </div>
          </div>
          <div className="cv_text_cont">
            <div className="cv_text_title2">
              Do you have problems in CV content?
            </div>
            <div className="cv_text_contents">
              Here are some helpful CV tips.
            </div>
          </div>
          <div className="underline_cv margin" />
        </div>
      </div>
      <div className="cv_builder_container">
        <div className="Personal_section">
          <div className="cv_title_personal_text">Personal Information</div>
          <p className="red">{error1} </p>
          <form>
            <div className="input_container">
              <div className="divss_container">
                <div className="right_div">
                  <div className="underline_cv" />
                  <div className="inputs_div margin">
                    <div className="input_div_title">
                      {" "}
                      First Name {!FirstName && <span className="red">*</span>}
                    </div>
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="First Name"
                      value={FirstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="underline_cv margin" />
                  <div className="inputs_div margin">
                    <div className="input_div_title">
                      {" "}
                      Last Name {!LastName && <span className="red">*</span>}
                    </div>
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="Last Name"
                      value={LastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="underline_cv margin" />
                  <div className="inputs_div margin">
                    <div className="input_div_title">
                      {" "}
                      Phone Number {!phone && <span className="red">*</span>}
                    </div>

                    <input
                      type="number"
                      className="input_normal_builder no-arrows"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="left_div">
                  <div className="underline_cv " />
                  <div className="inputs_div margin">
                    <div className="input_div_title">
                      {" "}
                      Address {!address && <span className="red">*</span>}
                    </div>
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      required
                    />
                  </div>
                  <div className="underline_cv margin" />
                  <div className="inputs_div margin ">
                    <div className="input_div_title">
                      {" "}
                      Email Address {!email && (
                        <span className="red">*</span>
                      )}{" "}
                      <div className="profile_description">
                        Use a professional e-mail
                      </div>
                    </div>
                    <p className="red">{error}</p>{" "}
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                    {/* <p className="red">{error}</p> */}
                  </div>

                  <div className="underline_cv margin" />
                  <div className="inputs_div margin">
                    <div className="input_div_title">
                      {" "}
                      School /University /Institute
                      {!school && <span className="red">*</span>}
                    </div>
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder=" Enter your School Name"
                      value={school}
                      onChange={(e) => setschool(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="underline_cv" />
              <div className="profile_div">
                <div className="input_div_title">
                  Profile <span className="red">*</span>
                </div>
                <div className="profile_description">
                  write two sentences to present yourself and skills to the
                  employer, mentioning the job position your applying for.
                </div>
                <textarea
                  className="profile_textarea"
                  placeholder="Profile"
                  value={profile}
                  // onChange={(e) => setprofile(e.target.value)}
                  onChange={(event) => handleProfile(event)}
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div className="Personal_section">
          <div className="cv_title_personal_text">Academic Achievments</div>

          <form>
            {education.map((educationItem, index) => {
              return (
                <>
                  <div key={index} className="education_container">
                    <div className="education_div">
                      <div className="education_title">Degree #{index + 1}</div>
                    </div>
                    <div className="underline_cv" />

                    <div className="degree_div">
                      <div className="input_div_title">
                        School /University /Institute{" "}
                        {!educationItem.school && (
                          <span className="red">*</span>
                        )}
                      </div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          name="school"
                          placeholder="Enter Your School"
                          className="input_normal_builder "
                          value={educationItem.school}
                          onChange={(event) => handleInputChange(index, event)}
                          required
                        />
                      </div>
                    </div>
                    <div className="underline_cv margin" />
                    <div className="degree_div">
                      <div className="input_div_title">
                        Degree{" "}
                        {!educationItem.degree && (
                          <span className="red">*</span>
                        )}
                      </div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          name="degree"
                          className="input_normal_builder"
                          placeholder="Enter your Degree"
                          value={educationItem.degree}
                          required
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </div>
                    </div>
                    <div className="underline_cv margin" />
                    <div className="degree_div">
                      <div className="input_div_title">
                        Specialization{" "}
                        {!educationItem.specialization && (
                          <span className="red">*</span>
                        )}
                      </div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          name="specialization"
                          placeholder="Enter Your Specialization"
                          className="input_normal_builder "
                          value={educationItem.specialization}
                          onChange={(event) => handleInputChange(index, event)}
                          required
                        />
                      </div>
                    </div>
                    <div className="underline_cv margin" />
                    <div className="dates_loc">
                      <div className="dates_div">
                        <div className="input_div_title">
                          Period{" "}
                          {(!educationItem.startDate ||
                            !(
                              educationItem.endDate || educationItem.present
                            )) && <span className="red">*</span>}
                        </div>
                        <div className="datesss_cont">
                          <label className="dates_in">
                            From :
                            <input
                              required
                              type="date"
                              name="startDate"
                              className="input_date_builder "
                              value={educationItem.startDate}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              max={educationItem.endDate}
                            />
                          </label>
                          {!educationItem.present && (
                            <label className="dates_in">
                              To :
                              <input
                                type="date"
                                name="endDate"
                                className="input_date_builder"
                                value={
                                  education.present
                                    ? "present"
                                    : education.endDate
                                }
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                min={educationItem.startDate}
                              />
                            </label>
                          )}
                          <label className="dates_in">
                            Present
                            <input
                              type="checkbox"
                              name="present"
                              className="input_small_builder_2"
                              checked={education.present}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="loc_div">
                        <div className="input_div_title">
                          Location{" "}
                          {!educationItem.location && (
                            <span className="red">*</span>
                          )}
                        </div>
                        <div className="inputsss_cont">
                          <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            className="input_normal_builder "
                            value={educationItem.location}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="button_contss">
                      {education.length < 3 && (
                        <div className="education_div_add">
                          <div
                            onClick={handleAddSection}
                            className="education_title_2"
                          >
                            Add Degree
                          </div>
                        </div>
                      )}
                      {index > 0 && (
                        <div className="education_div_remove">
                          <div
                            onClick={() => handleRemoveSection(index)}
                            className="education_title_3"
                          >
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </form>
        </div>
        <div className="Personal_section">
          <div className="cv_title_personal_text">Professional Experience</div>

          <form>
            {experiences.map((experiencesItem, index) => {
              return (
                <>
                  <div key={index} className="education_container">
                    <div className="education_div2">
                      <div className="education_title">
                        Experiences #{index + 1}
                      </div>
                    </div>
                    <div className="underline_cv" />

                    <div className="degree_div">
                      <div className="input_div_title">Company Name</div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          name="company"
                          placeholder="Enter Your Company"
                          className="input_normal_builder "
                          value={experiencesItem.company}
                          onChange={(event) =>
                            handleInputChangeexperience(index, event)
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="underline_cv margin" />
                    <div className="degree_div">
                      <div className="input_div_title">Position</div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          name="title"
                          className="input_normal_builder"
                          placeholder="Enter your Position"
                          value={experiencesItem.title}
                          required
                          onChange={(event) =>
                            handleInputChangeexperience(index, event)
                          }
                        />
                      </div>
                    </div>

                    <div className="underline_cv margin" />
                    <div className="dates_loc">
                      <div className="dates_div">
                        <div className="input_div_title">Period</div>
                        <div className="datesss_cont">
                          <label className="dates_in">
                            From :
                            <input
                              required
                              type="date"
                              name="startDate"
                              className="input_date_builder "
                              value={experiencesItem.startDate}
                              onChange={(event) =>
                                handleInputChangeexperience(index, event)
                              }
                              max={experiencesItem.endDate}
                            />
                          </label>
                          {!experiencesItem.present && (
                            <label className="dates_in">
                              To :
                              <input
                                type="date"
                                name="endDate"
                                className="input_date_builder"
                                value={
                                  experiences.present
                                    ? "present"
                                    : experiences.endDate
                                }
                                onChange={(event) =>
                                  handleInputChangeexperience(index, event)
                                }
                                min={experiencesItem.startDate}
                              />
                            </label>
                          )}
                          <label className="dates_in">
                            Present
                            <input
                              type="checkbox"
                              name="present"
                              className="input_small_builder_2"
                              checked={experiences.present}
                              onChange={(event) =>
                                handleInputChangeexperience(index, event)
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="loc_div">
                        <div className="input_div_title">Location </div>
                        <div className="inputsss_cont">
                          <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            className="input_normal_builder "
                            value={experiencesItem.location}
                            onChange={(event) =>
                              handleInputChangeexperience(index, event)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="profile_div">
                      <div className="input_div_title">Main Duties</div>
                      <textarea
                        className="profile_textarea"
                        placeholder="Main Duties"
                        name="description"
                        value={experiencesItem.description}
                        onChange={(event) =>
                          handleInputChangeexperience(index, event)
                        }
                        required
                      />
                      <div className="warning-message">
                        Please enter each duty on a new line starting with a '-'
                      </div>
                    </div>
                    <div className="button_contss">
                      {experiences.length < 4 && (
                        <div className="education_div_add_2">
                          <div
                            onClick={handleexperienceAddSection}
                            className="education_title_2"
                          >
                            Add Experience
                          </div>
                        </div>
                      )}
                      {index > 0 && (
                        <div className="education_div_remove">
                          <div
                            onClick={() => handleRemoveSectionexperience(index)}
                            className="education_title_3"
                          >
                            X
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </form>
        </div>
        <div className="Personal_section">
          <div className="cv_title_personal_text">Languages </div>

          <form>
            {languages.map((language, index) => {
              return (
                <>
                  <div key={language.id} className="education_container">
                    <div className="education_div2">
                      <div className="education_title">
                        Language #{index + 1}
                      </div>
                    </div>
                    <div className="underline_cv" />

                    <div className="degree_div">
                      <div className="input_div_title">
                        Language{" "}
                        {!language.name && <span className="red">*</span>}{" "}
                      </div>
                      <div className="inputsss_cont">
                        <input
                          type="text"
                          className="input_normal_builder"
                          placeholder="Language "
                          value={language.name}
                          onChange={(event) =>
                            handleLanguageChange(event, language.id, "name")
                          }
                        />
                      </div>
                    </div>

                    <div className="underline_cv margin" />
                    <div className="degree_div">
                      <div className="input_div_title">
                        Level{" "}
                        {!language.proficiency && (
                          <span className="red">*</span>
                        )}{" "}
                      </div>
                      <div className="levelss_cont">
                        <div
                          className="level_div"
                          data-value="Beginner"
                          onClick={(event) =>
                            handleLanguageChange(
                              event,
                              language.id,
                              "proficiency",
                            )
                          }
                        >
                          <div
                            className={
                              language.proficiency == "Beginner"
                                ? "inputdesignactive"
                                : "inputdesign"
                            }
                          />
                          <div className="title_level">Beginner</div>
                        </div>
                        <div
                          className="level_div"
                          data-value="Intermediate"
                          onClick={(event) =>
                            handleLanguageChange(
                              event,
                              language.id,
                              "proficiency",
                            )
                          }
                        >
                          <div
                            className={
                              language.proficiency == "Intermediate"
                                ? "inputdesignactive"
                                : "inputdesign"
                            }
                          />
                          <div className="title_level">Intermediate</div>
                        </div>
                        <div
                          className="level_div"
                          data-value="Advanced"
                          onClick={(event) =>
                            handleLanguageChange(
                              event,
                              language.id,
                              "proficiency",
                            )
                          }
                        >
                          <div
                            className={
                              language.proficiency == "Advanced"
                                ? "inputdesignactive"
                                : "inputdesign"
                            }
                          />
                          <div className="title_level">Advanced</div>
                        </div>
                        <div
                          className="level_div"
                          data-value="Mother Language"
                          onClick={(event) =>
                            handleLanguageChange(
                              event,
                              language.id,
                              "proficiency",
                            )
                          }
                        >
                          <div
                            className={
                              language.proficiency == "Mother Language"
                                ? "inputdesignactive"
                                : "inputdesign"
                            }
                          />
                          <div className="title_level">Mother Language</div>
                        </div>
                      </div>
                    </div>

                    <div className="button_contss">
                      {experiences.length < 4 && (
                        <div className="education_div_add_2">
                          <div
                            onClick={handleAddLanguage}
                            className="education_title_2"
                          >
                            Add Language
                          </div>
                        </div>
                      )}
                      {index > 0 && (
                        <button
                          onClick={() => handleRemoveLanguage(index)}
                          className="education_title_3_but"
                        >
                          X
                        </button>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </form>
        </div>
        <div className="Personal_section">
          <div className="cv_title_personal_text">Skills</div>

          <form>
            {skills.map((skill) => (
              <div key={skill.id} className="education_container">
                <div className="education_div2">
                  <div className="education_title">Skill #{skill.id}</div>
                </div>
                <div className="underline_cv" />
                <div className="degree_div">
                  <div className="input_div_title">
                    Skill Name {!skill.name && <span className="red">*</span>}{" "}
                  </div>
                  <div className="inputsss_cont">
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="Skill Name"
                      value={skill.name}
                      onChange={(event) => handleSkillChange(event, skill.id)}
                      onKeyPress={handleEnterKeyPress}
                    />
                  </div>
                </div>
                <div className="button_contss">
                  {skills.length < 10 && (
                    <div className="education_div_add_2">
                      <div
                        onClick={handleAddSkill}
                        className="education_title_2"
                      >
                        Add Skill
                      </div>
                    </div>
                  )}
                  {skills.length > 1 && (
                    <button
                      onClick={() => handleRemoveSkill(skill.id)}
                      className="education_title_3_but"
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="Personal_section">
          <div className="cv_title_personal_text">Other Activities</div>

          <form>
            {activities.map((activity) => (
              <div key={activity.id} className="education_container">
                <div className="education_div2">
                  <div className="education_title">Activity #{activity.id}</div>
                </div>
                <div className="underline_cv" />
                <div className="degree_div">
                  <div className="input_div_title">
                    Activity Name{" "}
                    {!activity.name && <span className="red">*</span>}{" "}
                    <div className="profile_description">
                      Scouts , camp, volunteering, fundraising
                    </div>
                  </div>

                  <div className="inputsss_cont">
                    <input
                      type="text"
                      className="input_normal_builder"
                      placeholder="Activity Name"
                      value={activity.name}
                      onChange={(event) =>
                        handleActivityChange(event, activity.id)
                      }
                      onKeyPress={handleActivityKeyPress}
                    />
                  </div>
                </div>
                <div className="button_contss">
                  {activities.length < 4 && (
                    <div className="education_div_add_2">
                      <div
                        onClick={handleAddActivity}
                        className="education_title_2"
                      >
                        Add Activity
                      </div>
                    </div>
                  )}
                  {activities.length > 1 && (
                    <button
                      onClick={() => handleRemoveActivity(activity.id)}
                      className="education_title_3_but"
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="button_generation">
          {fields ? (
            <div className="error_text_cont">
              <div className="error_text">Please Fill the Required Fields </div>
              <div className="button_cv_cont">
                <button className="generate_but" onClick={handlefields}>
                  {" "}
                  {loadingCreateCV ? "Generating your cv..." : "Generate Cv"}
                </button>

                <button
                  className={disabled ? "generate_butdisabled" : "generate_but"}
                >
                  <a
                    href={process.env.REACT_APP_API + "/CV/" + cv?.cv}
                    target="_blank"
                    className="white-link"
                  >
                    View CV
                  </a>{" "}
                </button>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div className="view_cv">
                {cv && cv.cv ? (
                  <button className="generate_but">
                    <a
                      href={process.env.REACT_APP_API + "/CV/" + cv?.cv}
                      target="_blank"
                      className="white-link"
                    >
                      View CV
                    </a>
                  </button>
                ) : (
                  <div className="error_text">
                    Please Generate a CV to Download Cv as PDF{" "}
                  </div>
                )}
              </div>
              <button
                className={disabled ? "generate_butdisabled" : "generate_but"}
                onClick={handlefields}
                disabled={disabled}
              >
                {" "}
                {loadingCreateCV ? "Generating your cv..." : "Generate Cv"}
              </button>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdssaABu7eVQeijqJ-xfGhDnht8OLHsk-v0v0JUAWLm5kdvPA/viewform?usp=pp_url"
                className="HELPP"
              >
                need help?
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CvBuilder;
