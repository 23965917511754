import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import heading from "../../assets/images/contactus/headingc.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Loader from "../../Components/Loader/Loader";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";

function ContactUs() {
  const [fullname, setfullname] = useState("");
  const [message, setmessage] = useState("");
  const [disabled, setdisabled] = useState("");

  const {
    main: submitContactUs,
    contactUs,
    loading,
  } = useAsync({
    fn: mehnatiApi.contactUs,
    onSuccess: () => {
      notifyUtils.success("Form was successfully submitted");
    },
    onError: () => {
      notifyUtils.error("Failed to submit form");
    },
  });

  const handlesubmit = () => {
    submitContactUs({
      fullName: fullname,
      email,
      number,
      message,
    });
  };

  //email regex
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [error1, setError1] = useState("");
  const [email, setemail] = useState("");

  useEffect(() => {
    if (emailRegex.test(email) || email === "") {
      setError1("");
    } else {
      setError1("Please enter a valid email address");
    }
  }, [email]);

  //phone number regex
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const [error2, setError2] = useState("");
  const [number, setnumber] = useState("");
  useEffect(() => {
    if (phoneRegex.test(number) || number === "") {
      setError2("");
    } else {
      setError2("Please enter a valid phone number");
    }
  }, [number]);

  //disable button

  useEffect(() => {
    if (
      fullname === "" ||
      email === "" ||
      number === "" ||
      message === "" ||
      error1 !== "" ||
      error2 !== ""
    ) {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  }, [fullname, email, number, message]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Main_contact">
          <div className="Aboutus_heading">
            <div className="Aboutus_heading_img">
              <img src={heading} alt="heading" className="heading" />
            </div>
            <div className="Aboutus_heading_text">
              <div className="Aboutus_heading_text_title"> Contact Us</div>
              <div className="Aboutus_heading_text_desc">
                {" "}
                <a href="/">Home</a>{" "}
                <span className="head_span"> / Contact Us</span>
              </div>
            </div>
          </div>
          <div className="Contactus_main">
            <div className="Contactus_main_left">
              <div className="Contactus_main_title">Send Message</div>

              <div className="Contactus_main_form_input">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="Contactus_main_form_input_name"
                  onChange={(e) => setfullname(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="Contactus_main_form_input_name"
                  onChange={(e) => setemail(e.target.value)}
                />
                <p className="INVALID"> {error1}</p>

                <PhoneInput
                  placeholder="Enter phone number"
                  value={number}
                  onChange={setnumber}
                  className="Contactus_main_form_input_name"
                />
                <p className="INVALID"> {error2}</p>

                <textarea
                  type="text"
                  placeholder="Message"
                  className="Contactus_main_form_input_subject"
                  onChange={(e) => setmessage(e.target.value)}
                />
              </div>
              <div className="message-div">{contactUs}</div>
              <div
                className="Contactus_main_form_button"
                onClick={handlesubmit}
              >
                <button
                  className={
                    disabled
                      ? "Aboutus_div_button_bt"
                      : "Aboutus_div_button_btnot"
                  }
                  disabled={disabled}
                >
                  Send
                </button>
              </div>
            </div>
            <div className="Contactus_main_right">
              <div className="Contactus_main_title">Contact Info</div>
              <div className="Contactus_main_right_infos_all">
                <div className="Contactus_main_right_info">
                  <IoLocationSharp size={30} className="cont_icon" /> Beirut,
                  Lebanon
                </div>
                <div className="Contactus_main_right_info">
                  <FaPhone size={30} className="cont_icon" /> +961 5 950255
                </div>
                <div className="Contactus_main_right_info">
                  <IoMdMail size={30} className="cont_icon" /> info@mehnati.org
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactUs;
