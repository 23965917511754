import React, { useState, useEffect, useRef } from "react";
import "./BurgerMenu.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import isAuth from "../../../Utils/isAuth";
import { getmyinfo } from "../../../Redux/User/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Auth/AuthActions";
import useUserInfo from "../../../hooks/useMyInfo";

function BurgerMenu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [islogedin, setislogedin] = useState(true);
  const [openJobs, setOpenJobs] = useState(false);
  const [openOurImpact, setOpenOurImpact] = useState(false);
  const [openPlatforms, setOpenPlatforms] = useState(false);
  const menuRef = useRef(null); // Reference for the menu

  const { userInfo, getMyInfo } = useUserInfo();

  useEffect(() => {
    setislogedin(isAuth(dispatch));
  }, [islogedin]);

  useEffect(() => {
    if (islogedin) {
      getMyInfo();
    }
  }, [islogedin]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        props.setIsShown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div className="Main_Burger_div">
      <div className="Burger_container" ref={menuRef}>
        <div className="close_burger" onClick={() => props.setIsShown(false)}>
          <AiOutlineCloseCircle size={30} className="close_burger_icon" />
        </div>
        {islogedin && (
          <div
            className="Burger_logedin_container"
            onClick={() => navigate("/profile")}
          >
            <div className="profile_image_container">
              <img
                src={process.env.REACT_APP_API + "/user/" + userInfo?.img_url}
                alt="profile"
                className="profile_image"
              />
            </div>
            <div className="Burger_logedin_text"> {userInfo?.full_name}</div>
          </div>
        )}

        <div className="Burger_nav_container">
          <div
            className="Burger_nav_item"
            onClick={() => {
              navigate("/aboutus");
              props.setIsShown(false);
            }}
          >
            <div className="Burger_nav_item_text">About us</div>
            <div className="divider" />
          </div>
          <div
            className="Burger_nav_item"
            onClick={() => setOpenJobs(!openJobs)}
          >
            <div className="Burger_nav_item_text">Jobs</div>
            <div className="divider" />
          </div>
          {openJobs && (
            <>
              <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/joblisting");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text green-color">
                  Find jobs
                </div>
                <div className="divider" />
              </div>
              <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/cvbuilder");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text green-color">
                  Create your CV
                </div>
                <div className="divider" />
              </div>
            </>
          )}

          <div
            className="Burger_nav_item"
            onClick={() => {
              navigate("/companies");
              props.setIsShown(false);
            }}
          >
            <div className="Burger_nav_item_text">Companies</div>
            <div className="divider" />
          </div>

          <div
            className="Burger_nav_item"
            onClick={() => setOpenOurImpact(!openOurImpact)}
          >
            <div className="Burger_nav_item_text">Our impact</div>
            <div className="divider" />
          </div>
          {openOurImpact && (
            <>
              <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/cvbuilder");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text green-color">
                  Sucess Stories
                </div>
                <div className="divider" />
              </div>
              <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/qna");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text green-color">Q&A</div>
                <div className="divider" />
              </div>
            </>
          )}

          <div
            className="Burger_nav_item"
            onClick={() => {
              navigate("/contactus");
              props.setIsShown(false);
            }}
          >
            <div className="Burger_nav_item_text">Get involved</div>
            <div className="divider" />
          </div>

          <div
            className="Burger_nav_item"
            onClick={() => setOpenPlatforms(!openPlatforms)}
          >
            <div className="Burger_nav_item_text">Platforms</div>
            <div className="divider" />
          </div>

          {openPlatforms && (
            <>
              <div
                className="Burger_nav_item"
                onClick={() => (window.location.href = "https://mehnati.org/")}
              >
                <div className="Burger_nav_item_text green-color">Mehnati</div>
                <div className="divider" />
              </div>
              <div
                className="Burger_nav_item"
                onClick={() =>
                  (window.location.href = "https://lms.mehnati.org/")
                }
              >
                <div className="Burger_nav_item_text green-color">Lms</div>
                <div className="divider" />
              </div>
              <div
                className="Burger_nav_item"
                onClick={() =>
                  (window.location.href = "https://schools.mehnati.org/")
                }
              >
                <div className="Burger_nav_item_text green-color">Schools</div>
                <div className="divider" />
              </div>
            </>
          )}

          {islogedin ? (
            <div className="Burger_nav_item" onClick={handleLogout}>
              <div className="Burger_nav_item_text">Log out</div>
              <div className="divider" />
            </div>
          ) : (
            <>
              {/* <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/login");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text">Login</div>
                <div className="divider" />
              </div> */}

              <div
                className="Burger_nav_item"
                onClick={() => {
                  navigate("/auth");
                  props.setIsShown(false);
                }}
              >
                <div className="Burger_nav_item_text">Account</div>
                <div className="divider" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
