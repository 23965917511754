import useAsync from "./useAsync";
import mehnatiApi from "../api";
import notifyUtils from "../Utils/notify";
import notify from "../Utils/notify";

export default function useJobs({ onApplySuccess = () => {} } = {}) {
  const {
    main: getJobs,
    result: jobs,
    loading: loadingJobs,
  } = useAsync({
    fn: mehnatiApi.getJobs,
    onError: () => {},
    onSuccess: () => {},
  });

  const {
    main: getJobById,
    result: job,
    loading: loadingJob,
  } = useAsync({
    fn: mehnatiApi.getJobById,
    onError: () => {},
    onSuccess: () => {},
  });

  const { main: applyForJob, error } = useAsync({
    fn: mehnatiApi.applyForJob,
    onError: () => {
      notifyUtils.error("Failed to apply for job");
    },
    onSuccess: () => {
      onApplySuccess();
      notifyUtils.success(
        "Check your email regulary to stay update on your application",
      );
    },
  });

  const { main: checkIfJobApplied, result: appliedForJob } = useAsync({
    fn: mehnatiApi.checkIfJobApplied,
    onSuccess: () => {},
    onError: () => {},
  });

  const {
    main: getAppliedJobs,
    result: appliedJobs,
    loading: loadingAppliedJobs,
  } = useAsync({
    fn: mehnatiApi.getAppliedJobs,
    onError: () => {
      notifyUtils.error("Failed to get applied jobs");
    },
  });

  return {
    getJobs,
    loadingJobs,
    loadingJob,
    getJobById,
    applyForJob,
    checkIfJobApplied,
    jobs,
    job,
    appliedForJob,
    getAppliedJobs,
    appliedJobs,
    loadingAppliedJobs,
  };
}
