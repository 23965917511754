import React, { useState, useEffect } from "react";
import "./EditProfile.css";
import Loader from "../../Components/Loader/Loader";
import { RiDeleteBin2Line } from "react-icons/ri";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import useDistrictsList from "../../hooks/useDistrictsList";
import useDegreeList from "../../hooks/useDegreeList";
import useExpertiseList from "../../hooks/useExpertiseList";
import useExperienceLevelList from "../../hooks/useExperienceLevelList";
import useUserInfo from "../../hooks/useMyInfo";

function EditProfile({ seteditprofile }) {
  const [Fullname, setFullname] = useState("");
  const [Email, setEmail] = useState("");
  const [Gender, setGender] = useState("");
  const [Phone, setPhone] = useState("");
  const [Year, setYear] = useState("");
  const [District, setDistrict] = useState("");
  const [School, setSchool] = useState("");
  const [Certificate, setCertificate] = useState("");
  const [educationallevel, seteducationallevel] = useState(0);
  const [expertise, setexpertise] = useState(0);
  const [experiencelevel, setexperiencelevel] = useState(0);
  // const [profilepic, setprofilepic] = useState("");
  const [cv, setcv] = useState("");
  const [cover, setcover] = useState("");
  const [certificateName, setcertificateName] = useState("");
  // const [profilepicc, setprofilepicc] = useState("");
  const [portfolio, setportfolio] = useState("");
  const [portfolioname, setportfolioname] = useState("");
  const [coverlettername, setcoverlettername] = useState("");
  const [cvname, setcvname] = useState("");
  const [imagename, setImagename] = useState("");

  const {
    userInfo,
    getMyInfo,
    editMyInfo,
    editMyImg,
    loadingUserInfo: loading,
  } = useUserInfo();

  const { districtsList } = useDistrictsList();
  const { degreeList } = useDegreeList();
  const { expertiseList } = useExpertiseList();
  const { experienceLevelList } = useExperienceLevelList();

  const handleFileSelected = (event) => {
    const fileInput = event.target;
    setcertificateName(fileInput.files[0].name);
    setCertificate(fileInput.files[0]);
  };
  const handleFileSelected1 = (event) => {
    const fileInput = event.target;
    setImagename(fileInput.files[0]?.name);
    setSelectedImage(fileInput.files[0]);
  };
  const handleFileSelected2 = (event) => {
    const fileInput = event.target;
    setportfolioname(fileInput.files[0].name);
    setportfolio(fileInput.files[0]);
  };
  const handleFileSelected3 = (event) => {
    const fileInput = event.target;
    setcoverlettername(fileInput.files[0].name);
    setcover(fileInput.files[0]);
  };
  const handleFileSelected4 = (event) => {
    const fileInput = event.target;
    setcvname(fileInput.files[0].name);
    setcv(fileInput.files[0]);
  };

  const [image, setimage] = useState(null);
  const [selectedImage, setSelectedImage] = useState();

  const handleImageSelected = (event) => {
    const imageInput = event.target;
    setSelectedImage(null);

    if (imageInput.files && imageInput.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage(e.target.result);

        setImagename(imageInput.files[0]?.name);

        setimage(imageInput.files[0]);
      };

      reader.readAsDataURL(imageInput.files[0]);
    }
  };

  useEffect(() => {
    getMyInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setFullname(userInfo.full_name);
      setEmail(userInfo.email);
      setGender(userInfo.gender);
      setPhone(userInfo.phone_number);
      setYear(userInfo.year_of_birthday);
      setSchool(userInfo.school);
      setDistrict(userInfo.district_id);
      seteducationallevel(
        userInfo.user_education_levels?.[0].education_level_id
      );
      setexpertise(userInfo.user_expertises?.[0].experise_id);
      setexperiencelevel(
        userInfo.user_experience_levels?.[0].experience_level_id
      );
      setcertificateName(userInfo.certificate);
      setportfolioname(userInfo.portfolio);
      setcoverlettername(userInfo.cover_letter);
      setcvname(userInfo.cv);
      setImagename(userInfo?.img_url);
    }
  }, [userInfo]);

  const handleEdit = async (e) => {
    e.preventDefault();
    const data = {
      full_name: Fullname,
      email: Email,
      gender: Gender,
      phone_number: Phone,
      year_of_birthday: Year,
      school: School,
      district_id: District,
      education_level: [parseInt(educationallevel)],
      experience_level: [parseInt(experiencelevel)],
      expertise: [parseInt(expertise)],
    };

    const docs = new FormData();

    if (selectedImage) {
      docs.append("img_url", selectedImage);
    }
    if (Certificate) {
      docs.append("certificate", Certificate);
    }
    if (portfolio) {
      docs.append("portfolio", portfolio);
    }
    if (cover) {
      docs.append("cover_letter", cover);
    }
    if (cv) {
      docs.append("cv", cv);
    }
    editMyInfo(data);
    if (docs) {
      editMyImg(docs);
    }
  };

  return (
    <div className="Main_edit">
      <div className="edit_container">
        <div className="Edit_text"> Edit Information </div>
        <NotificationContainer />
        <form>
          {loading ? (
            <div className="loader_container">
              <Loader />
            </div>
          ) : (
            <>
              <div className="info_container">
                <div className="text_container1"> Full Name </div>
                <input
                  type="text"
                  value={Fullname}
                  className="input_complete"
                  required
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className="info_container">
                <div className="text_container1"> Email </div>
                <input
                  type="text"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input_complete"
                  required
                  readOnly
                />
              </div>

              <div className="info_container">
                <div className="text_container1"> Gender </div>
                <select
                  className="input_complete styleDrop"
                  required
                  value={Gender}
                  onChange={(e) => setGender(e.target.valur)}
                >
                  <option value="Male"> Male </option>
                  <option value="Female"> Female </option>
                </select>
              </div>

              <div className="info_container">
                <div className="text_container1">Phone Number</div>
                <input
                  type="text"
                  require
                  className="input_complete"
                  required
                  value={Phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="info_container">
                <div className="text_container1">Year of birth</div>
                <input
                  type="date"
                  placeholder="0123456789"
                  className="input_complete"
                  required
                  value={Year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
              <div className="info_container">
                <div className="text_container1"> Address By District </div>
                <select
                  className="input_complete styleDrop"
                  required
                  value={District}
                  onChange={(e) => setDistrict(e.target.value)}
                >
                  {districtsList &&
                    districtsList.map((item) => (
                      <option value={item.id}>{item.name_en}</option>
                    ))}
                </select>
              </div>

              <div className="info_container">
                <div className="text_container1">School </div>
                <input
                  type="text"
                  placeholder="0123456789"
                  className="input_complete"
                  required
                  value={School}
                  onChange={(e) => setSchool(e.target.value)}
                />
              </div>
              <div className="info_container">
                <div className="text_container1">Educational Level </div>
                <select
                  className="input_complete styleDrop"
                  required
                  value={educationallevel}
                  onChange={(e) => seteducationallevel(e.target.value)}
                >
                  {degreeList &&
                    degreeList.map((item) => (
                      <option value={item.id}>{item.name_en}</option>
                    ))}
                </select>
              </div>
              <div className="info_container">
                <div className="text_container1">Field of Experience </div>
                <select
                  className="input_complete styleDrop"
                  required
                  value={expertise}
                  onChange={(e) => setexpertise(e.target.value)}
                >
                  {expertiseList &&
                    expertiseList.map((item) => (
                      <option value={item.id}>{item.name_en}</option>
                    ))}
                </select>
              </div>
              <div className="info_container">
                <div className="text_container1 ">Experience Level </div>
                <select
                  className="input_complete styleDrop"
                  required
                  value={experiencelevel}
                  onChange={(e) => setexperiencelevel(e.target.value)}
                >
                  {experienceLevelList &&
                    experienceLevelList.map((item) => (
                      <option value={item.id}>{item.name_en}</option>
                    ))}
                </select>
              </div>

              <div className="info_container">
                <div className="text_container2"> Profile Photo </div>
                <div className="upload_files">
                  <label for="f1" className="custom-file-upload">
                    <div className="upload_button2">
                      {" "}
                      Upload your Profile Photo{" "}
                    </div>
                  </label>

                  <div className="border">
                    <input
                      id="f1"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(event) => handleFileSelected1(event)}
                    />
                    <input
                      type="text"
                      value={imagename}
                      className="input_file_editprofile"
                      disabled
                    />
                    <div
                      onClick={() => {
                        setSelectedImage(null);
                        setImagename("");
                      }}
                    >
                      <RiDeleteBin2Line className="delete" />
                    </div>
                  </div>
                </div>
              </div>

              <div></div>

              <div className="Edit_container1">
                <div className="info_container">
                  <div className="text_container2"> Curriculum Vitae </div>
                  <div className="upload_files">
                    <label for="f2" className="custom-file-upload">
                      <div className="upload_button2"> Upload your Cv </div>
                    </label>

                    <div className="border">
                      <input
                        id="f2"
                        type="file"
                        placeholder="Certificate acquired"
                        accept="application/pdf"
                        className="hidden"
                        onChange={(event) => handleFileSelected4(event)}
                      />
                      <input
                        type="text"
                        value={cvname}
                        className="input_file_editprofile"
                        disabled
                      />

                      <div
                        onClick={() => {
                          setcv(null);
                          setcvname("");
                        }}
                      >
                        <RiDeleteBin2Line className="delete" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="Edit_container1">
                  <div className="info_container">
                    <div className="text_container2"> Cover Letter </div>
                    <div className="upload_files">
                      <label for="file-upload3" className="custom-file-upload">
                        <div className="upload_button2">
                          {" "}
                          Upload Cover Letter{" "}
                        </div>
                      </label>

                      <div className="border">
                        <input
                          id="file-upload3"
                          type="file"
                          placeholder="Certificate acquired"
                          accept="application/pdf"
                          className="hidden"
                          onChange={(event) => handleFileSelected3(event)}
                        />
                        <input
                          type="text"
                          value={coverlettername}
                          className="input_file_editprofile"
                          disabled
                        />

                        <div
                          onClick={() => {
                            setcoverlettername("");
                            setcover(null);
                          }}
                        >
                          <RiDeleteBin2Line className="delete" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="Edit_container1">
                  {/* <div className="info_container">
                <div className="text_container2"> Portfolio </div>
                <div className="upload_files">
                  <button className="upload_button"> Upload Portfolio </button>
                  <div className="border">
                    <input type="file" className="input_file" />
                  </div>
                </div>
              </div> */}
                  <div className="info_container">
                    <div className="text_container2"> Portfolio </div>
                    <div className="upload_files">
                      <label for="file-upload2" className="custom-file-upload">
                        <div className="upload_button2"> Upload Portfolio </div>
                      </label>

                      <div className="border">
                        <input
                          id="file-upload2"
                          type="file"
                          placeholder="Certificate acquired"
                          accept="application/pdf"
                          className="hidden"
                          onChange={(event) => handleFileSelected2(event)}
                        />
                        <input
                          type="text"
                          value={portfolioname}
                          className="input_file_editprofile"
                          disabled
                        />
                        <div
                          onClick={() => {
                            setportfolioname("");
                            setportfolio(null);
                          }}
                        >
                          <RiDeleteBin2Line className="delete" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Edit_container1">
                <div className="info_container">
                  <div className="text_container2"> Certificate </div>
                  <div className="upload_files">
                    <label for="file-upload" className="custom-file-upload">
                      <div className="upload_button2"> Upload Certificate </div>
                    </label>

                    <div className="border">
                      <input
                        id="file-upload"
                        type="file"
                        placeholder="Certificate acquired"
                        accept="application/pdf"
                        className="hidden"
                        onChange={(event) => handleFileSelected(event)}
                      />
                      <input
                        type="text"
                        value={certificateName}
                        className="input_file_editprofile"
                        disabled
                      />
                      <div
                        onClick={() => {
                          setcertificateName("");
                          setCertificate(null);
                        }}
                      >
                        <RiDeleteBin2Line className="delete" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="update" onClick={handleEdit}>
                {" "}
                Update{" "}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
