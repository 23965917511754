import React, { useEffect, useState, useRef } from "react";
import "./Companies.css";
import { GrSearch } from "react-icons/gr";
import heading from "../../assets/images/aboutus/Path 489.svg";
import { IoIosArrowForward } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";
import Loader from "../../Components/Loader/Loader";
import CompaniesCard from "../../Components/CompaniesCard/CompaniesCard";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
import useAsync from "../../hooks/useAsync";

function Companies() {
  const [search, setSearch] = useState("");
  const [mainActivities, setMainActivities] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState([]);

  const handlereset = () => {
    setSelectedActivity([]);
    setSearch("");
  };

  const { result: activities, main: getActivities } = useAsync({
    fn: mehnatiApi.getActivities,
    onError: () => {
      notifyUtils.error("Error getting activities!");
    },
  });

  const {
    result: companies,
    main: getCompanies,
    loading,
  } = useAsync({
    fn: mehnatiApi.getCompanies,
    onError: () => {
      notifyUtils.error("Error getting companies!");
    },
  });

  const handleActivities = () => {
    setMainActivities(!mainActivities);
  };
  const handleActivitySelection = (activityId) => {
    if (selectedActivity.includes(activityId)) {
      const filteredArray = selectedActivity.filter(
        (element) => element !== activityId,
      );
      setSelectedActivity(filteredArray);
    } else {
      setSelectedActivity([...selectedActivity, activityId]);
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    getCompanies({
      activityIds: selectedActivity,
      search: search,
    });
  }, [selectedActivity, search]);

  const droplistRef = useRef(null);

  // Use an effect to attach a click event listener when the component mounts
  useEffect(() => {
    function handleClickOutside(event) {
      if (droplistRef.current && !droplistRef.current.contains(event.target)) {
        setMainActivities(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="Main_company">
        <div className="Aboutus_heading">
          <div className="Aboutus_heading_img">
            <img src={heading} alt="heading" className="heading" />
          </div>

          <div className="Aboutus_heading_text">
            <div className="Aboutus_heading_text_title_3"> Companies</div>
            {/* <div className="Aboutus_heading_text_desc">
            {" "}
            Home/ <span className="head_span">Companies Registration</span>
          </div> */}
            <div className="Aboutus_heading_text_desc">
              {" "}
              <a href="/">Home /</a>{" "}
              <span className="head_span">Companies</span>
            </div>
          </div>
        </div>
        <div className="joblisting_body">
          <div className="filter_bar">
            <div className="upperfilters_box">
              <div className="search_box">
                <label className="cont_search">
                  <GrSearch size={30} className="search_icon" />
                  <input
                    type="text"
                    className="input_complete_search"
                    placeholder="Search for Keywords"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div ref={droplistRef} className="filter_icons_container">
              <div className="icons_drop">
                <div className="icons_dropdown">
                  <div className="title_drop_cont" onClick={handleActivities}>
                    <div className="title_drop">Main Activity</div>
                    <div className="arrow_cont">
                      <IoIosArrowForward
                        size={30}
                        className={
                          mainActivities ? "arrow_icon" : "arrow_icon_rot"
                        }
                      />
                    </div>
                  </div>
                  {mainActivities && (
                    <div className="Header_nav_dropdown_job">
                      <div className="Header_nav_dropdown_cont">
                        {activities &&
                          activities.map((activity) => (
                            <div
                              key={activity.id}
                              className="subsection_text_jobs"
                            >
                              <input
                                type="checkbox"
                                value={activity.id}
                                name="category_filter"
                                className="checkbox_filter"
                                id={`category_${activity.id}`}
                                checked={selectedActivity.includes(activity.id)}
                                onChange={() => {
                                  handleActivitySelection(activity.id);
                                }}
                              />
                              <label htmlFor={`category_${activity.id}`}>
                                {activity.title}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="title_drop_cont" onClick={handlereset}>
                  <div className="trash_cont">
                    <BsFillTrashFill size={30} className="trash_icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="companies_cont">
            {loading ? (
              <Loader />
            ) : (
              <>
                {companies &&
                  companies.map((activities) => (
                    <div key={activities.id}>
                      <div className="activities_name">
                        {activities?.title ? activities?.title : ""}
                      </div>

                      {activities?.company_activities.length > 0 && (
                        <Carousel
                          responsive={responsive}
                          autoPlay={true}
                          centerMode={true}
                          showDots={false}
                          itemClass="carousel-item"
                          infiniteLoop={true}
                          swipeable={false}
                          draggable={false}
                          infinite={true}
                        >
                          {activities?.company_activities?.map((companies) => (
                            <CompaniesCard
                              key={companies.id}
                              logo={companies?.company?.img_url}
                              name={companies?.company?.full_name}
                              id={companies.id}
                              companyId={companies?.company?.id}
                              category={activities.title}
                              district={companies?.company?.district?.name_en}
                            />
                          ))}
                        </Carousel>
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Companies;
