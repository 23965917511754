import React, { useEffect, useState } from "react";
import "./SuccessStories.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import SucessStories from "../../Components/SucessStories/SucessStories";
import { Link } from "react-router-dom";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
function SuccessStories() {
  const { result: successStories, main: getSuccessStories } = useAsync({
    fn: mehnatiApi.getSuccessStories,
    onError: () => {
      notifyUtils.error("Failed to get success stories");
    },
  });

  useEffect(() => {
    getSuccessStories();
  }, []);

  return (
    <div className="Main_success_page">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Success Stories</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <Link to="/"> Home </Link> /{" "}
            <span className="head_span">Success Stories</span>
          </div>
        </div>
      </div>
      <div className="success_stories">
        <div className="success_stories_heading">Success Stories</div>
        <div className="success_container">
          {successStories?.map((item) => (
            <SucessStories
              img={item?.img_url}
              status={item.status}
              date={item.time}
              name={item.full_name}
              text={item.information}
              position={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default SuccessStories;
