import React, { useEffect, useState } from "react";
import "./Auth.css";
import { useNavigate } from "react-router-dom";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import { Link } from "react-router-dom";
import notifyUtils from "../../Utils/notify";

function Auth() {
  const navigate = useNavigate();

  return (
    <div className="Main_auth">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Account </div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a> <span className="head_span">Account</span>
          </div>
        </div>
      </div>{" "}
      <div className="auth_cont">
        <div className="home_section4_cont">
          <div className="home_section_auth">
            <div className="home_career_text_cont_4">
              <div className="home_career_title_sec4">
                {" "}
                Are you a job seeker?
              </div>
              <div className="home_career_subtitle_sec4">
                {" "}
                Search, Apply & Get a Job
              </div>
              <div className="home_career_title2_sec4"> Join Us Now</div>
              <div className="home_section4_button">
                <div
                  className="home_section4_button_text"
                  onClick={() => navigate("/completeprofile")}
                >
                  Register{" "}
                </div>
              </div>
              <div className="home_career_title2_sec4"> Already a Member ?</div>
              <div className="home_section4_button">
                <div
                  className="home_section4_button_text_5"
                  onClick={() => navigate("/login")}
                >
                  Login{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="home_section_auth_2">
            {" "}
            <div className="home_career_text_cont_4">
              <div className="home_career_title_sec4">
                {" "}
                Are you an employer?
              </div>
              <div className="home_career_subtitle_sec4">
                {" "}
                Find and hire Professionals
              </div>
              <div className="home_career_title2_sec4"> Join Us Now</div>
              <div className="home_section4_button">
                <div
                  className="home_section4_button_text"
                  onClick={() => navigate("/registercompany")}
                >
                  Register{" "}
                </div>
              </div>
              <div className="home_career_title2_sec4"> Already a Member ?</div>
              <div className="home_section4_button">
                <a
                  href="https://admin.jobs.mehnati.org/#/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="home_section4_button_text_5">Login</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
