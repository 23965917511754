import useAsync from "./useAsync";
import mehnatiApi from "../api";
import notifyUtils from "../Utils/notify";

export default function useSavedJobs({
  onUnsaveSuccess = () => {},
  onSaveSuccess = () => {},
} = {}) {
  const { result: savedJobs, main: getSavedJobs } = useAsync({
    fn: mehnatiApi.getSavedJobs,
    onError: () => {
      notifyUtils.error("Failed to get saved jobs");
    },
  });

  const { main: unsaveJob } = useAsync({
    fn: mehnatiApi.unsaveJob,
    onError: () => {
      notifyUtils.error("Failed to delete saved job");
    },
    onSuccess: () => {
      onUnsaveSuccess();
    },
  });

  const { main: saveJob } = useAsync({
    fn: mehnatiApi.saveJob,
    onError: () => {
      notifyUtils.error("Failed to save job");
    },
    onSuccess: () => {
      onSaveSuccess();
    },
  });

  return { savedJobs, getSavedJobs, saveJob, unsaveJob };
}
