import mehantiApi from "../api";
import useAsync from "./useAsync";
import notifyUtils from "../Utils/notify";
import { useEffect } from "react";

export default function useExperienceLevelList() {
  const { result: experienceLevelList, main: getExperienceLevelList } =
    useAsync({
      fn: mehantiApi.getExperienceList,
      onError: () => {
        notifyUtils.error("Failed to get experience list");
      },
    });

  useEffect(() => {
    getExperienceLevelList();
  }, []);

  return { experienceLevelList, getExperienceLevelList };
}
