import { toast } from "sonner";

const success = (message) => {
  toast.success(message, {
    position: "top-right",
  });
};

const error = (message) => {
  toast.error(message, {
    position: "top-right",
  });
};

const notifyUtils = {
  success,
  error,
};

export default notifyUtils;
