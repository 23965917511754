import mehantiApi from "../api";
import useAsync from "./useAsync";
import { useEffect } from "react";

export default function useExpertiseList() {
  const { result: expertiseList, main: getExpertiseList } = useAsync({
    fn: mehantiApi.getExpertiseList,
    onError: () => {},
    onSuccess: () => {},
  });

  useEffect(() => {
    getExpertiseList();
  }, []);

  return { expertiseList, getExpertiseList };
}
