import React, { useEffect } from "react";
import "./AppliedJobsList.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import Applied_Jobs from "../../Components/Profile/Applied_jobs";
import Loader from "../../Components/Loader/Loader";
import useJobs from "../../hooks/useJobs";

function AppliedJobsList() {
  const { getAppliedJobs, appliedJobs, loadingAppliedJobs } = useJobs();

  useEffect(() => {
    getAppliedJobs();
  }, []);

  return (
    <div className="applied_list">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Applied Jobs List</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>{" "}
            <span className="head_span">Applied Jobs</span>
          </div>
        </div>
      </div>
      <div className="applied_list_container">
        {loadingAppliedJobs ? (
          <div className="loader_container">
            <Loader />
          </div>
        ) : (
          <>
            {appliedJobs &&
              appliedJobs?.map((job) => (
                <Applied_Jobs
                  key={job.id}
                  position={job.job.title_en}
                  location={job.job.district.name_en}
                  createdDate={job.createdAt}
                  buttontext={job.status}
                  id={job?.job?.id}
                  reason={job.reason}
                  className="HIRE"
                  image={job.job.company?.img_url}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default AppliedJobsList;
